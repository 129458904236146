// import * as React from 'react';

  
    import React, { useEffect, useState } from "react";
    import ReactMarkdown from "react-markdown";
    // import { hostInfo } from "../../../../service";
    import { Link, useNavigate, useParams } from "react-router-dom";
    import { SlArrowRight } from "react-icons/sl";
    import { Helmet } from 'react-helmet';
    import { hostInfo , token } from '../server';
   
async function fetchData(title) {
  // const nevigate = useNavigate();
  try {
    const res = await fetch(`${hostInfo}/get_blogs_details`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      },
      body: JSON.stringify({ title: title }),
    });
    
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await res.json();
    
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // nevigate('/ipo-news');
    return { error: "Failed to fetch data" };
  }
}

function NewsDetails({deviceInfo,navigation}) {
  const Params = useParams();
  console.log(Params["ipo-news"]);
  const nevigate = useNavigate();
  const [data, setData] = useState(null);
  console.log(data)

  useEffect(() => {
    fetchData(Params["ipo-news"]).then((data) => {
      setData(data.blog_info[0].data);
    }).catch((error) => {
      // Handle error
      console.error("Error fetching data:", error);
      nevigate('/ipo-news');
     
     
    });
  }, [Params["ipo-news"]]);

 
  
    const extra = data && data[7]
      ? Object.keys(JSON.parse(data[7]))
      : "";
  
    const bikeDescription = `
   ${
     data && data.length > 0 ? data[6] : "No description available"
   }
   `;
  
    const biketags = `
     ${
       data && data.length > 0
         ? data[5]
             .split(",")
             .map((tag) => `- ${tag.trim()}`)
             .join("\n")
         : ""
     }
   `;
  

  
   const tooltipText = data && data[2];
 
   // Function to truncate text to 3-4 words
   const truncateText = (text, wordLimit) => {
     const words = text?.split(' ');
     if (words?.length > wordLimit) {
       return words?.slice(0, wordLimit).join(' ') + '...';
     }
     return text;
   };
 
   const truncatedText = truncateText(tooltipText, 4); // Truncate to 4 words
 
 
 
  
  //  const tooltipText = data && data[2];
  //  // Function to truncate text to 3-4 words
  // const truncateText = (text, wordLimit) => {
  //   const words = text?.split(' ');
  //   if (words?.length > wordLimit) {
  //     return words?.slice(0, wordLimit).join(' ') + '...';
  //   }
  //   return text;
  // };

  // const truncatedText = truncateText(tooltipText, 4); // Truncate to 4 words


  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    width: "100%",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "space-between",
    alignItems: "center"
  };

  const imageDivStyle = {
    flex: "1",
    maxWidth: isMobile ? "100%" : "470px",
    width: "100%",
    order: isMobile ? "2" : "1"
  };

  const textDivStyle = {
    flex: "1",
    maxWidth: isMobile ? "100%" : "500px",
    width: "100%",
    order: isMobile ? "1" : "2"
  };

  const imageStyle = {
    width: '100%',
    height: '250px',
    objectFit: 'cover'
  };
    
      return (
    //     <div className="flex justify-center pb-5">
    //   <div className="mt-24 w-[800px] ">
      
    //     <img
    //       src={data && data[3]}
    //       height="100"
    //       width="900"
    //       alt="Image"
    //       className="w-full h-[400px]"
    //     />
    
    //         <div
    //           // key={i.id}
    //           className="text-sm mt-4 text-right mb-2 text-gray-500"
    //         >
    //           {new Date(data && data[8]).toLocaleString("en-US", {
    //             month: "short",
    //             day: "numeric",
    //             year: "numeric",
    //           })}
    //         </div>
      

    //     <div className="text-[40px]">{data && data[2]}</div>
    //     <div className="text-gray-500 mb-4">
    //       {data && data[4].length > 130
    //         ? data && data[4].slice(0, 130) + "..."
    //         : data && data[4]}
    //     </div>

    //     <hr className="mb-4 w-64"></hr>
    //     <h2 className="text-[40px]">Description of {data && data[2]}</h2>
    
    //     <ReactMarkdown className="markdown">{bikeDescription}</ReactMarkdown>
    //     <hr className="mb-4 w-64"></hr>
    //     {data &&
    //       data.length > 0 &&
    //       data[5].split(",").map((tag, index) => (
    //         <button
    //           key={index}
    //           style={{
    //             padding: "1px 10px",
    //             margin: "5px",
    //             marginLeft: "0px",
    //             background: "#f9f9f9",
    //             borderRadius: "5px",
    //           }}
    //         >
    //           {tag.trim()}
    //         </button>
    //       ))}

    //     <ReactMarkdown className="markdown">{biketags}</ReactMarkdown> 
    
    //   </div>
    // </div>
<>
<Helmet>
<title>{`${data && data[2]} - IPOtank`}</title>
<meta name="description" content={data && data[4]} />
<meta name="keywords" content={`${data && data[2]}, IPO news, IPO updates, IPO analysis, IPOtank, IPO tank`} />

<meta property="og:url" content={`https://www.ipotank.com/news/ipo/${encodeURIComponent(tooltipText?.toLowerCase().replace(/ /g, '-'))}`} /> <meta property="og:type" content="website" /> <meta property="og:title" content={`${data && data[2]} - IPOtank`} /> <meta property="og:description" content={data && data[4]} />
</Helmet>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    > 
    
         <div style={{ width: '90%', maxWidth: '1000px'}}>
         {(deviceInfo === "app" && navigation) ? null : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "10px", color:"#666",paddingTop:"10px"}} >
      <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
        <p>
          Home
        </p>
      </Link>
      <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
    
      <Link to={`/ipo-news`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        <p>News</p>
      </Link>
      <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      {/* <Link to={`/news/ipo/${encodeURIComponent(data && data[2].toLowerCase().replace(/ /g, '-'))}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        <p>{data && data[2]}</p>
      </Link> */}
      {/* <div style={{ position: 'relative', display: 'inline-block' }}>
      <Link
        to={`/news/ipo/${encodeURIComponent(tooltipText?.toLowerCase().replace(/ /g, '-'))}`}
        style={linkStyle}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onTouchStart={() => setShowTooltip(true)} // Show tooltip on touch start for mobile devices
        onTouchEnd={() => setShowTooltip(false)} // Hide tooltip on touch end for mobile devices
      >
        <p>{truncatedText}</p>
      </Link>
      <div style={tooltipStyle}>{tooltipText}</div>
    </div> */}
      <Link
      to={`/news/ipo/${encodeURIComponent(tooltipText?.toLowerCase().replace(/ /g, '-'))}`}
      title={tooltipText} // Simple tooltip using the title attribute
      onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'}
      style={{ fontWeight: 'bold', cursor: 'pointer', color: '#666', textDecoration: 'none' }}
    >
      <p>{truncatedText}</p>
    </Link>
    </div>)}
    
    </div> </div>

    {(deviceInfo === "app" && navigation) ? null : (<div style={{ borderTop: "1px solid #dddcdd"}} ></div>)}

    <div
  style={{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}}
> 
    <div style={{ width: '90%', maxWidth: '1000px',marginBottom:"20px"}}>
 
    {/* <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px' }}> */}
  <div style={{ marginTop: '20px' }}>
  {/* <div style={{display:"flex",flexDirection:"row",width:"100%",flexWrap:"wrap",gap:"15px",justifyContent:"space-between",alignItems:"center"}}><div style={{maxWidth:"400px"}}>
    <img
      src={data && data[3]}
      height="100"
      width="900"
      alt="Image"
      style={{ width: '100%', height: '250px' }}
    /></div>
    <div style={{width:"100%",maxWidth:"500px"}}>
    <div
      style={{
        fontSize: '0.875rem',
        marginTop: '0.5rem',
        textAlign: 'right',
        marginBottom: '0.5rem',
        color: '#666',
        // #718096
      }}
    >Published by : {data && data[10]} | {new Date(data && data[8]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
    </div>
  

    <div style={{ fontSize: '1.5rem' }}>{data && data[2]}</div>
    
    <div style={{ color: '#666', margin: '1rem 0',textAlign: "justify" }}>
        {data && data[4]}
    </div>

   
    <div style={{ borderTop: "1px solid #dddcdd"}} ></div></div></div> */}

<div style={containerStyle}>
      <div style={imageDivStyle}>
        <img
          src={data && data[3]}
          alt="Image"
          style={imageStyle}
        />
      </div>
      <div style={textDivStyle}>
        <div
          style={{
            fontSize: '0.875rem',
            marginTop: '0.5rem',
            textAlign: 'right',
            marginBottom: '0.5rem',
            color: '#666',
          }}
        >
          Published by : {data && data[10]} | {new Date(data && data[8]).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </div>
        <div style={{ fontSize: '1.5rem' }}>{data && data[2]}</div>
        <div style={{ color: '#666', margin: '1rem 0', textAlign: "justify" }}>
          {data && data[4]}
        </div>
        <div style={{ borderTop: "1px solid #dddcdd" }}></div>
      </div>
    </div>


    <div style={{ fontSize: '1.5rem',margin:"15px 0px",fontWeight:"bold" }}>Description of {data && data[2]}</div>
    
    <div className="markdown" style={{color:"#666",textAlign: "justify"}}> {bikeDescription} </div>

    <div style={{ borderTop: "1px dashed #dddcdd",margin:"18px 0"}} ></div>

    {data &&
      data.length > 0 &&
      data[5].split(",").map((tag, index) => (
        <button
          key={index}
          style={{
            padding: '1px 10px',
            margin: '5px',
            marginLeft: '0px',
            background: 'whitesmoke',
            borderRadius: '5px',
            border:"1px solid whitesmoke"
          }}
        >
          {tag.trim()}
        </button>
      ))}
    
    {/* <div className="markdown" style={{}}> {biketags} </div> */}
    
  </div>
</div>
</div>
{/* </div> */}
</>
      );
    }
    

    
  export default NewsDetails;