import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        backgroundColor: theme.palette.primary,
        color: theme.palette.primary,
    },
    '& .MuiDialogContent-root': {
        // padding: theme.spacing(3),
    },
    '& .MuiDialogContentText-root': {
        fontSize: '1rem',
    },
    '& .MuiDialogActions-root': {
        justifyContent: 'right',
        // padding: theme.spacing(2),
    },
    '& .MuiButton-root': {
        // justifyContent: 'right',
        margin: theme.spacing(1),
        // fontSize: '1rem',
    },
}));

const ConfirmDialog = ({ open, onClose, onConfirm }) => (
    <StyledDialog open={open} onClose={onClose}>
        <DialogTitle>Confirm Navigation</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You're being redirected to an external site. Click Yes to venture further or Cancel to remain here!
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" variant="contained" size="small">
                Cancel
            </Button>
            <Button onClick={onConfirm} color="success" variant="contained" size="small">
                Yes
            </Button>
        </DialogActions>
    </StyledDialog>
);

export default ConfirmDialog;
