import React from 'react';

const ShareModal = ({ message, onClose }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(message).then(() => {
      alert('Message copied to clipboard');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const generateShareLink = (platform) => {
    const encodedMessage = encodeURIComponent(message);
    switch (platform) {
      case 'whatsapp':
        return `https://wa.me/?text=${encodedMessage}`;
      case 'gmail':
        return `https://mail.google.com/mail/?view=cm&fs=1&body=${encodedMessage}`;
      case 'email':
        return `mailto:?body=${encodedMessage}`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?text=${encodedMessage}`;
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodedMessage}`;
      default:
        return '#';
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Share IPO Details</h2>
        <textarea value={message} readOnly rows="10" cols="50" />
        <button onClick={copyToClipboard}>Copy to Clipboard</button>
        <button onClick={() => window.open(generateShareLink('whatsapp'), '_blank')}>Share via WhatsApp</button>
        <button onClick={() => window.open(generateShareLink('gmail'), '_blank')}>Share via Gmail</button>
        <button onClick={() => window.open(generateShareLink('email'), '_blank')}>Share via Email</button>
        <button onClick={() => window.open(generateShareLink('twitter'), '_blank')}>Share via Twitter</button>
        <button onClick={() => window.open(generateShareLink('facebook'), '_blank')}>Share via Facebook</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ShareModal;
