import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
// // import { CardHeader } from "@mui/material";
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import '../brokers.css';
import '../buyback.css';
import ConfirmDialog from './ConfirmDialog';
import IPOStatusComponent from "./IPOStatusComponent";
import FaqComponent from './FaqComponent'; 

function Buyback({allBuybacks,deviceInfo,navigation}) {

  const faqData = [
    {
      question: "What is a share buyback?",
      answer: "A share buyback occurs when a company repurchases its own shares from the market. This reduces the number of outstanding shares, often increasing the value of the remaining shares."
    },
    {
      question: "Why do companies initiate buybacks?",
      answer: "Companies initiate buybacks to return excess cash to shareholders, boost share value, improve financial ratios like earnings per share (EPS), and show confidence in the company's future growth."
    },
    {
      question: "How do shareholders benefit from a buyback?",
      answer: "Shareholders benefit from a buyback in two main ways: by selling their shares back to the company at a premium or by holding onto their shares, which may increase in value as the total number of outstanding shares decreases."
    },
    {
      question: "Are there any risks associated with buybacks?",
      answer: "Yes, buybacks can be risky. If the company overestimates its financial health, it may deplete resources needed for operations or growth. Additionally, the stock price may not increase as expected, potentially leaving shareholders at a disadvantage."
    },
    {
      question: "How do brokers assist with buyback transactions?",
      answer: "Brokers facilitate buyback transactions by informing clients about the buyback offer, helping them with the tendering of shares, and ensuring smooth execution of the process."
    },
    {
      question: "Do brokers charge fees for buyback transactions?",
      answer: "Brokers may charge a commission or processing fee for handling buyback transactions. The fee structure depends on the broker and the value of the shares tendered."
    },
    {
      question: "What information do I need to provide to participate in a buyback?",
      answer: "To participate in a buyback, you need to provide your Demat account details, PAN, and the number of shares you wish to tender. Your broker may assist with the submission process."
    },
    {
      question: "Can I choose to participate in a buyback or not?",
      answer: "Yes, participation in a buyback is voluntary. Shareholders can decide whether to tender their shares or hold onto them, depending on their financial strategy and expectations for the company's performance."
    },
    {
      question: "What happens if I don’t participate in the buyback?",
      answer: "If you don't participate in the buyback, your shares will remain unchanged, but you could benefit indirectly from an increase in the share price due to the reduced number of outstanding shares."
    },
    {
      question: "How do brokers handle payments for tendered shares in a buyback?",
      answer: "Once your shares are accepted in a buyback, brokers ensure the payment for the tendered shares is credited to your bank account. This typically occurs within a few business days after the buyback process is complete."
    },
    {
      question: "Can brokers guarantee the acceptance of my shares in a buyback?",
      answer: "No, brokers cannot guarantee the acceptance of shares in a buyback. The acceptance ratio depends on the number of shares the company plans to repurchase and the total shares tendered by shareholders."
    },
    {
      question: "What are the tax implications of selling shares in a buyback?",
      answer: "When participating in a buyback, the sale of shares may be subject to capital gains tax. The rate depends on how long the shares were held and the prevailing tax laws in your region."
    },
    {
      question: "How do brokers provide updates on the buyback process?",
      answer: "Brokers keep clients informed by providing updates on the buyback timeline, the acceptance ratio, and the final settlement details. Some brokers also offer research and insights on the financial impact of the buyback."
    },
    {
      question: "What compliance requirements must brokers follow during a buyback?",
      answer: "Brokers must comply with regulatory requirements, including adhering to SEBI guidelines and KYC norms, ensuring transparency in the buyback process, and properly executing client orders."
    },
    {
      question: "What are the advantages of participating in a buyback?",
      answer: "Participating in a buyback can provide liquidity, allow shareholders to sell shares at a premium, and help improve the company’s financial ratios. However, each shareholder’s decision will depend on their individual financial goals."
    }
  ];
  
 console.log(allBuybacks);
  // const [iconPills, setIconPills] = React.useState("1");
  // const [pills, setPills] = React.useState("1");
  const [ipoType, setIpoType] = useState("All IPO");

  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  const handleIpoTypeChange = (type) => {
    setIpoType(type);
    setActiveTab(type);
    setShowAllRows(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ff7847",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }


  // // Filter rows based on IPO type
  // const filteredRows =
  //   ipoType === "All IPO"
  //     ? currentIPOs
  //     : currentIPOs.filter((row) => {
  //         if (ipoType === "Mainboard IPO") {
  //           const lastValue = row[8] && row[8].split(" ").pop();
  //           return lastValue === "NSE";
  //         } else if (ipoType === "SME IPO") {
  //           const lastValue = row[8] && row[8].split(" ").pop();
  //           return lastValue === "SME";
  //         }
  //       });

  const [showAllRows, setShowAllRows] = useState(false);
  const [activeTab, setActiveTab] = useState("All IPO");
  const initialRowCount = 6;

  // const visibleRows = showAllRows ? filteredRows.length : initialRowCount;

  const openExternal = (url) => {
    window.open(url, '_blank');
  };
  
  const handleDivClick = (url) => {
    // Redirect to another page when clicking outside the buttons
    window.location.href = url;
  };

  const handleButtonClick = (event, url) => {
    event.stopPropagation(); // Prevent the div's onClick from triggering
    if (url) {
      openExternal(url);
    }
  };

  function shuffleArray(array) {
    return array.slice().sort(() => Math.random() - 0.5);
  }
  // Filter the brokers where Broker_Banner is an empty string
  const filteredBrokers = allBuybacks && allBuybacks?.filter(broker => broker.Broker_Banner !== "");
  const shuffledBrokers = filteredBrokers && shuffleArray(filteredBrokers)?.slice(0, 4);




  const [selectedFilter, setSelectedFilter] = useState('All');

  const [visibleCount, setVisibleCount] = useState(10);


  // // Filtered Buybacks based on selected button
  // const filteredBuybacks = allBuybacks.filter((buyback) => {
  //   if (selectedFilter === 'All') return true;
  //   if (selectedFilter === 'Current') return buyback.buyback_type === 'Current Buyback';
  //   if (selectedFilter === 'Upcoming') return buyback.buyback_type === 'Upcoming Buyback';
  //   if (selectedFilter === 'Closed') return buyback.buyback_type === 'Closed Buyback';
  //   return true;
  // });


// Get the current date
// Helper function to remove the time component from a date
const stripTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

// Get the current date without time
const currentDate = stripTime(new Date());

// Filtered Buybacks based on selected button
const filteredBuybacks = allBuybacks
  .filter((buyback) => {
    // Convert buyback.close_date to a Date object and remove time for comparison
    const closeDate = stripTime(new Date(buyback.close_date));
    // setVisibleCount(10);
    if (selectedFilter === 'All') return true;

    // For Current filter: return buybacks where currentDate <= closeDate
    if (selectedFilter === 'Current') return currentDate <= closeDate;

    // For Closed filter: return buybacks where currentDate > closeDate
    if (selectedFilter === 'Closed') return currentDate > closeDate;

    // For Upcoming filter: return buyback type matches 'Upcoming Buyback'
    if (selectedFilter === 'Upcoming') return buyback.buyback_type === 'Upcoming Buyback';

    return true;
  })
  // Sort by record_date in descending order for all cases
  .sort((a, b) => new Date(b.record_date) - new Date(a.record_date));





  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };

  const handleShowLess = () => {
    setVisibleCount(10); // Reset to the initial 10 items
  };


  // const [selectedFilter, setSelectedFilter] = useState('All');


  return (
    <>
    <Helmet>
    <title>{`Top Share Buybacks in India ${new Date().getFullYear()} | Key Opportunities for Investors`}</title>
    <meta name="description" content={`Discover the leading share buybacks in India for ${new Date().getFullYear()}. Learn about buyback opportunities, company strategies, and how to benefit from share repurchase programs.`} />
    <meta name="keywords" content={`Share Buybacks, Top Buybacks ${new Date().getFullYear()}, Buyback Opportunities, Investment Strategies, Stock Repurchase, Buyback Experts, IPOtank, IPO tank`} />

    <meta property="og:url" content="https://www.ipotank.com/buybacks" /> <meta property="og:type" content="website" /> <meta property="og:title" content={`Top Share Buybacks in India ${new Date().getFullYear()} | Key Opportunities for Investors`} /> <meta property="og:description" content={`Discover the leading share buybacks in India for ${new Date().getFullYear()}. Learn about buyback opportunities, company strategies, and how to benefit from share repurchase programs.`} />
</Helmet>


      <div style={{backgroundImage: "url('../best8.jfif')",backgroundSize: "cover", backgroundRepeat: "no-repeat", padding:"10px 0 10px 0"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
           <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
           {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        {/* <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link> */}
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        <Link to={`/buyback`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>Buyback</p>
        </Link>
      </div>)}
      <br/>
    
      <h1 style={{marginTop:"-20px"}}>Buyback</h1>
      <p style={{marginTop:"-10px", textAlign: "justify",fontSize:"15px"}}>Explore the top share buybacks in India for 2024. Discover key investment opportunities, learn about company buyback strategies, and find out how to maximize your returns through leading buyback programs.</p>
      {/* <h4 style={{marginTop:"10px"}}>A "Current IPO" refers to an Initial Public Offering that is currently open for subscription or recently closed but not yet listed on a stock exchange. It represents a pivotal moment for a private company transitioning to a publicly traded entity, offering shares to investors for the first time to raise capital.</h4> */}
      {/* <p style={{marginTop:"0px", textAlign: "justify",fontSize:"15px"}}>List of all current IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Stay updated on the latest IPOs and their specifics in one place.</p> */}
      </div>
      </div>
      </div>
      <br/>
      {/* <br/> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

{allBuybacks && allBuybacks?.length > 0 ? ( <>
           <div style={{ width: '93%', maxWidth: '1200px' ,  display: "flex",
          flexDirection: "row",flexWrap:"wrap",gap:"20px" }}>
      
     <div style={{ 
         width:"100%",
        //  maxWidth:"900px",minWidth:"250px",
        // width:"auto",
         height:"auto",display:"flex",flexDirection:"column",gap:"10px"}}>
     
    {/* {allBuybacks && allBuybacks.length > 0 ? ( <> */}


    {allBuybacks && allBuybacks.length > 0 ? (
        <>
          {/* Filter Buttons */}
          {/* <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', margin: '0 0 10px 0' }}>
            <button className="filter-btn" onClick={() => setSelectedFilter('All')}>All Buybacks</button>
            <button className="filter-btn" onClick={() => setSelectedFilter('Current')}>Current Buybacks</button>
            <button className="filter-btn" onClick={() => setSelectedFilter('Upcoming')}>Upcoming Buybacks</button>
            <button className="filter-btn" onClick={() => setSelectedFilter('Closed')}>Closed Buybacks</button>
          </div> */}
          {/* <div className="button-containers" style={{borderBottom:"1px solid #ccc",paddingBottom:"10px"}}>
  <button className="filter-btns" onClick={() => setSelectedFilter('All')}>All</button>
  <button className="filter-btns" onClick={() => setSelectedFilter('Current')}>Current</button>
  <button className="filter-btns" onClick={() => setSelectedFilter('Upcoming')}>Upcoming</button>
  <button className="filter-btns" onClick={() => setSelectedFilter('Closed')}>Closed</button>
</div> */}

<div className="button-containers" style={{borderBottom: "1px solid #ccc", paddingBottom: "10px"}}>
      <button
        className={`filter-btns ${selectedFilter === 'All' ? 'active' : ''}`}  // Add 'active' class if 'All' is selected
        onClick={() => {setSelectedFilter('All');
          setVisibleCount(10);
        }}
      >
        All
      </button>
      <button
        className={`filter-btns ${selectedFilter === 'Current' ? 'active' : ''}`}  // Add 'active' class if 'Current' is selected
        onClick={() => {setSelectedFilter('Current');
          setVisibleCount(10);
        }}
      >
        Current
      </button>
      <button
        className={`filter-btns ${selectedFilter === 'Upcoming' ? 'active' : ''}`}  // Add 'active' class if 'Upcoming' is selected
        onClick={() => {setSelectedFilter('Upcoming');
          setVisibleCount(10);
        }}
      >
        Upcoming
      </button>
      <button
        className={`filter-btns ${selectedFilter === 'Closed' ? 'active' : ''}`}  // Add 'active' class if 'Closed' is selected
        onClick={() => {setSelectedFilter('Closed');
          setVisibleCount(10);
        }}
      >
        Closed
      </button>
    </div>


          <div >
            <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', gap: '15px' }}>


            {filteredBuybacks.length > 0 ?(<>{filteredBuybacks.slice(0, visibleCount).map((buyback) => (
        <div key={buyback.id} className="card-body gap-3">
          <Link to={`/buyback/${buyback.ipo_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
            <div className="top_row">
              <div className="ipo_cover pb-3">
                <img
                  src={buyback.image_url}
                  alt={buyback.ipo_name}
                  style={{
                    height: '100px',
                    objectFit: 'contain',
                    width: '100px',
                  }}
                  loading="lazy"
                />
              </div>
              <h4 className="ipo_name px-1">{buyback.ipo_name}</h4>
            </div>
          </Link>

          <div className="broker_content">
            <div className="d-flex flex-wrap gap-2 flex-grow-1">
              <span className="badge_span">
                <i className="fa fa-check-circle" aria-hidden="true"></i> Tender Offer
              </span>
              <Link to={`/buyback/${buyback.ipo_route_name}`} className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto">
                View Details
              </Link>
            </div>

            <Link to={`/buyback/${buyback.ipo_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
              <div className="d-flex flex-wrap otherStatus mt-4">
                <div className="data_box">
                  <p className="label">Record Date</p>
                  <span data-role="charges">{new Date(buyback.record_date).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Date</p>
                  <span data-role="charges">{new Date(buyback.issue_date).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Close Date</p>
                  <span data-role="charges">{new Date(buyback.close_date).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Buyback Price</p>
                  <span data-role="charges">{buyback.buyback_price}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Size</p>
                  <span data-role="charges">{buyback.issue_size.split(' ')[0]}</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      ))}</>):(<div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"5px",marginBottom:"30px"}}><img src="../../hand-drawn-no-data-illustration.avif" alt="" width="250px" height="250px" /><div style={{fontSize:"16px",textAlign:"center"}}><strong>No buybacks available at the moment. Check back again soon!</strong></div><div style={{fontSize:"14px",textAlign:"center"}}>"When companies buy back shares, they are reinforcing their confidence in their own success."</div></div>)} 

      {/* Show 'View More' if there are more items to load */}
      {visibleCount < filteredBuybacks.length && filteredBuybacks.length > 0 && (
        <div className="text-center mt-3" style={{textAlign:"center"}}>
          <button className="btn view-more-btns" onClick={handleViewMore}>
            View More
          </button>
        </div>
      )}

      {/* Show 'Show Less' only when all items are visible */}
      {visibleCount >= filteredBuybacks.length && filteredBuybacks.length > 0 && visibleCount > 10 && (
        <div className="text-center mt-3" style={{textAlign:"center"}}>
          <button className="btn show-less-btns" onClick={handleShowLess}>
            Show Less
          </button>
        </div>
      )}

      
              {/* {filteredBuybacks.map((buyback) => (
                <div key={buyback.id} className="card-body gap-3">
                  <Link to={`/buyback/${buyback.ipo_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <div className="top_row">
                      <div className="ipo_cover pb-3">
                        <img
                          src={buyback.image_url}
                          alt={buyback.ipo_name}
                          style={{
                            height: '100px',
                            objectFit: 'contain',
                            width: '100px',
                          }}
                          loading="lazy"
                        />
                      </div>
                      <h4 className="ipo_name px-1">{buyback.ipo_name}</h4>
                    </div>
                  </Link>

                  <div className="broker_content">
                    <div className="d-flex flex-wrap gap-2 flex-grow-1">
                      <span className="badge_span">
                        <i className="fa fa-check-circle" aria-hidden="true"></i> Tender Offer
                      </span>
                      <Link to={`/buyback/${buyback.ipo_route_name}`} className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto">
                        View Details
                      </Link>
                    </div>

                    <Link to={`/buyback/${buyback.ipo_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
                      <div className="d-flex flex-wrap otherStatus mt-4">
                        <div className="data_box">
                          <p className="label">Record Date</p>
                          <span data-role="charges">{new Date(buyback.record_date).toDateString()}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Issue Date</p>
                          <span data-role="charges">{new Date(buyback.issue_date).toDateString()}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Close Date</p>
                          <span data-role="charges">{new Date(buyback.close_date).toDateString()}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Buyback Price</p>
                          <span data-role="charges">{buyback.buyback_price}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Issue Size</p>
                          <span data-role="charges">{buyback.issue_size.split(' ')[0]}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))} */}
            </div>

            {/* Sidebar Brokers */}
            {/* <div style={{ width: 'auto', height: 'auto' }}>
              <div className="sideBoxContainer">
                {shuffledBrokers?.map((broker) => (
                  <a className="sideBox fadeUp" href={broker.Open_Demat_Account_Url} target="_blank" title={broker.Broker_Name} rel="noopener noreferrer">
                    <p className="cardTitle" style={{ textDecoration: 'none', color: 'black', marginBottom: '10px' }}>{broker.Broker_Name}</p>
                    <img src={broker.Broker_Banner} alt={broker.Broker_Name} className="cardImage" loading="lazy" style={{ marginBottom: '15px' }} />
                    <button className="btn knowMoreBtn" type="button">Know More</button>
                  </a>
                ))}
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>
      )}








    {/* {allBuybacks?.map((buyback) => (
      <div key={buyback.id} className="card-body gap-3">
      <Link to={`/buyback/${buyback.ipo_route_name}`} style={{textDecoration:"none",color:"black"}}>
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src={buyback.image_url}
            alt={buyback.ipo_name}
            // maxWidth="100px"
            // width="100%"
            // height="100px"
            loading="lazy"

            style={{ 
                // width: 100%;
                height: "100px",
                objectFit: "contain",
                width:"100px",


            // maxWidth: "200px",
            // minWidth: "100px",
            // height: "120px",
            // borderRadius: "8px",
            // overflow: "hidden",
            // display: "flex",
            // alignItems: "flex-start"
        }}
           
          />
        </div>
        <h4 className="ipo_name px-1" >{buyback.ipo_name}</h4>
      </div></Link>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1" onClick={()=>handleDivClick(`/buyback/${buyback.ipo_name?.toLowerCase().replace(" ","-")}`)}>
       
          <span className="badge_span">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Tender Offer
          </span>
     
          
         
          <a onClick={(event) => handleButtonClick(event)}  className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href={`/buyback/${buyback.ipo_name?.toLowerCase().replace(" ","-")}`}>
            View Details
          </a>
         
        </div>
        <Link to={`/buyback/${buyback.ipo_route_name}`} style={{textDecoration:"none",color:"black"}}>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">	
            <p className="label">Record Date</p>
            <span data-role="charges">{new Date(buyback.record_date).toDateString()}</span>
          </div>
          <div className="data_box">
            <p className="label">Issue Date</p>
            <span data-role="charges">{new Date(buyback.issue_date).toDateString()}</span>
          </div>
          <div className="data_box">
            <p className="label">Close Date</p>
            <span data-role="charges">{new Date(buyback.close_date).toDateString()}</span>
          </div>
          <div className="data_box">
            <p className="label">Buyback Price</p>
            <span data-role="charges">{buyback.buyback_price}</span>
          </div>
          <div className="data_box">
            <p className="label">Issue Size</p>
            <span data-role="charges">{buyback.issue_size.split(" ")[0]}</span>
          </div>
        </div></Link>
      </div>
    </div> ))} */}
   
    


   

    
      </div>

      {/* <div style={{ 
    //    minWidth:"200px",
    width:"auto",
    height:"auto" }}>
   <div  className="sideBoxContainer">
   {shuffledBrokers?.map((broker) => (
    <a className="sideBox fadeUp" 
       href={broker.Open_Demat_Account_Url} 
       target="_blank" 
       title={broker.Broker_Name} 
       rel="noopener noreferrer" >
       
      <p className="cardTitle" style={{textDecoration:"none",color:"black",marginBottom:"10px"}}>{broker.Broker_Name}</p>
      <img 
        src={broker.Broker_Banner} 
        alt={broker.Broker_Name} 
        className="cardImage" 
        loading="lazy" 
        style={{marginBottom:"15px"}}
      />
      <button 
        className="btn knowMoreBtn" 
        // role="button" 
        type="button">
        Know More
      </button>
    </a>))}
 
    </div>
     
   

      


     
      </div> */}

      
      </div>
      </>):(<div className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>)}

      <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
        <br/>
        </div>

        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "93%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="Buybacks" /></div></div>
     
    </>
  );
}

export default Buyback;
