import React from 'react';
import { Helmet } from 'react-helmet';
import FaqComponentPage from './FaqComponentPage';

const SupportAndFAQs = () => {
  const styles = {
    container: {
      marginTop:"100px",
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      padding: '20px',
      color: '#333',
      fontSize:"15px",
    },
    header1: {
      fontSize: '2em',
      fontWeight: 'bold',
      marginBottom: '10px',
      paddingBottom: '10px',
      borderBottom:"1px solid #ccc"
    },
    header: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    sectionHeader: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    section: {
      marginBottom: '20px',
    },
    list: {
      paddingLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    contact: {
      marginTop: '30px',
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    emailLink: {
      color: '#007BFF',
      textDecoration: 'none',
    },
  };

  return (
    <>
    {/* <Helmet>
   <title>Privacy Policy IPOtank</title>
   <meta name="description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit IPO tank." />
   <meta name="keywords" content="Privacy Policy, personal information protection, data privacy, GDPR, CCPA, privacy practices, IPOtank, IPO tank" />
 
 
   <meta property="og:url" content="https://www.ipotank.com/support-faqs" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Privacy Policy IPOtank" />
    <meta property="og:description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit IPO tank." />
  </Helmet> */}
  <Helmet>
  <title>Support & FAQs - IPOtank</title>
  <meta name="description" content="Find answers to common questions and get support for any issues you may have at IPOtank. Learn more about how IPOtank works, our services, and how to get in touch with us." />
  <meta name="keywords" content="Support, FAQs, help, questions, customer support, IPOtank, IPO tank, assistance, troubleshooting" />

  <meta property="og:url" content="https://www.ipotank.com/support-faqs" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Support & FAQs - IPOtank" />
  <meta property="og:description" content="Need help with IPOtank? Visit our Support & FAQs page for answers to your questions and guidance on using our services." />
</Helmet>


  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"30px",
      textAlign:"justify",
    }}
  >
       <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    {/* <div style={styles.container}> */}
      <div style={styles.header1} id="h-privacy-policy-for-IPOtank">
      Support & FAQs
      </div>
      <p style={{marginBottom:"20px"}}>Find answers to your questions about IPO investments, application processes, and more at IPOtank. Our Support & FAQs section provides helpful guidance and solutions to common issues for a seamless IPO experience.</p>

      <h2 style={styles.header} id="h-contact-us">
      IPO FAQs
      </h2>
      <FaqComponentPage  type="Home" />

      <h2 style={styles.header} id="h-contact-us">
      Current IPO FAQs
      </h2>
      <FaqComponentPage  type="Current IPO" />
      
      <h2 style={styles.header} id="h-contact-us">
      Closed IPO FAQs
      </h2>
      <FaqComponentPage  type="Closed IPO" />

      <h2 style={styles.header} id="h-contact-us">
      Upcoming IPO FAQs
      </h2>
      <FaqComponentPage  type="Upcoming IPO" />

      <h2 style={styles.header} id="h-contact-us">
      Recently Listed IPO FAQs
      </h2>
      <FaqComponentPage  type="Recently Listed IPO" />

      <h2 style={styles.header} id="h-contact-us">
      IPO GMP FAQs
      </h2>
      <FaqComponentPage  type="GMP" />

      <h2 style={styles.header} id="h-contact-us">
      IPO Performance FAQs
      </h2>
      <FaqComponentPage  type="Performance" />

      <h2 style={styles.header} id="h-contact-us">
      Brokers FAQs
      </h2>
      <FaqComponentPage  type="Brokers" />

      <h2 style={styles.header} id="h-contact-us">
      Buybacks FAQs
      </h2>
      <FaqComponentPage  type="Buybacks" />

      <h2 style={styles.header} id="h-contact-us">
      Rights Issues FAQs
      </h2>
      <FaqComponentPage  type="Rights Issues" />
   
      <h2 style={styles.header} id="h-contact-us">
        Contact Us
      </h2>
      <p>
        If you have any questions about this FAQs, You can contact us:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          By email: <a href="mailto:ipotank@gmail.com" style={styles.emailLink}>ipotank@gmail.com</a>
        </li>
      </ul>
    </div></div>
  
   
    
    </>
  );
};

export default SupportAndFAQs;
