import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import ConfirmDialog from './ConfirmDialog';
import IPOStatusComponent from "./IPOStatusComponent";
import FaqComponent from './FaqComponent'; 

function ClosedIPOs({closedIPOs,deviceInfo,navigation}) {

  const faqData = [
    {
      question: "What does it mean when an IPO is closed?",
      answer: "A closed IPO means that the subscription period for the IPO has ended, and no further applications for shares can be made. The next steps include the allotment process, refund (if applicable), and listing of shares on the stock exchange."
    },
    {
      question: "How can I check if I was allotted shares in the closed IPO?",
      answer: "You can check the allotment status of the closed IPO by visiting the registrar's website and entering your PAN number or application number. The allotment status is usually announced a few days after the IPO closes."
    },
    {
      question: "What happens if I did not receive any shares in the closed IPO?",
      answer: "If you did not receive any shares in the closed IPO, the funds you used to apply will be refunded to your bank account. The refund process typically starts shortly after the allotment is announced."
    },
    {
      question: "When will the shares from the closed IPO be listed?",
      answer: "The listing date for the shares from the closed IPO is typically announced shortly after the allotment process is complete. The shares will start trading on the stock exchange on the listing day."
    },
    {
      question: "Can I still buy shares after the IPO is closed?",
      answer: "Yes, you can buy shares after the IPO is closed, but you will have to wait until the shares are listed on the stock exchange. Once listed, you can purchase them at the market price."
    },
    {
      question: "What should I do if I face issues with the refund for the closed IPO?",
      answer: "If you face issues with the refund for the closed IPO, you should contact your broker or the IPO's registrar. They can provide assistance in resolving any refund-related problems."
    },
    {
      question: "How can I track the performance of shares from a closed IPO?",
      answer: "You can track the performance of shares from a closed IPO by monitoring the stock market once the shares are listed. Financial news platforms and your broker's app will provide real-time updates on the share price and performance."
    },
    {
      question: "What is the role of the registrar in a closed IPO?",
      answer: "The registrar in a closed IPO is responsible for handling the allotment process, processing refunds, and assisting investors with any issues related to their applications. They act as a bridge between the company and the investors."
    },
    {
      question: "Is there a lock-in period for shares purchased in a closed IPO?",
      answer: "Yes, for certain categories of investors, such as promoters and anchor investors, there is a lock-in period during which they cannot sell their shares. Retail investors typically do not have a lock-in period, but this can vary."
    },
    {
      question: "Can I see the final subscription numbers for a closed IPO?",
      answer: "Yes, the final subscription numbers for a closed IPO are usually published by the stock exchanges and can be accessed through financial news portals. These numbers show how much the IPO was oversubscribed in different investor categories."
    },
    {
      question: "How does the market price on listing day compare to the issue price of the closed IPO?",
      answer: "The market price on listing day can be higher, lower, or the same as the issue price, depending on investor demand and market conditions. Many factors, including overall market sentiment and the company's perceived value, influence the listing price."
    },
    {
      question: "What should I consider before selling shares from a closed IPO on the listing day?",
      answer: "Before selling shares from a closed IPO on the listing day, consider factors such as the company's long-term prospects, market conditions, and your investment goals. Some investors may choose to sell immediately for a profit, while others may hold for potential long-term gains."
    },
    {
      question: "How do I report issues or complaints related to a closed IPO?",
      answer: "You can report issues or complaints related to a closed IPO by contacting the IPO's registrar or your broker. For unresolved issues, you may escalate the matter to the Securities and Exchange Board of India (SEBI)."
    }
  ];
  
  
 // const [iconPills, setIconPills] = React.useState("1");
  // const [pills, setPills] = React.useState("1");
  const [ipoType, setIpoType] = useState("All IPO");

  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  const handleIpoTypeChange = (type) => {
    setIpoType(type);
    setActiveTab(type);
    setShowAllRows(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ff7847",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }


  // Filter rows based on IPO type
  const filteredRows =
    ipoType === "All IPO"
      ? closedIPOs
      : closedIPOs.filter((row) => {
          if (ipoType === "Mainboard IPO") {
            const lastValue = row[8] && row[8].split(" ").pop();
            return lastValue === "NSE";
          } else if (ipoType === "SME IPO") {
            const lastValue = row[8] && row[8].split(" ").pop();
            return lastValue === "SME";
          }
        });

  const [showAllRows, setShowAllRows] = useState(false);
  const [activeTab, setActiveTab] = useState("All IPO");
  const initialRowCount = 6;

  const visibleRows = showAllRows ? filteredRows.length : initialRowCount;

  return (
    <>
  <Helmet>
  <title>{`Closed IPO - Check Latest List of Closed IPOs in ${new Date().getFullYear()} | IPOtank`}</title>
  <meta name="description" content="Closed IPO: Get a list of all Closed IPOs along with details like Date, lot size, Price, Subscription, allotment status and much more at IPO tank." />
  <meta name="keywords" content="closed IPO, IPO performance, IPO outcomes, IPO, IPOtank, IPO tank" />

  <meta property="og:url" content="https://www.ipotank.com/closed-ipo" /> <meta property="og:type" content="website" /> <meta property="og:title" content={`Closed IPO - Check Latest List of Closed IPOs in ${new Date().getFullYear()} | IPOtank`} /> <meta property="og:description" content="Closed IPO: Get a list of all Closed IPOs along with details like Date, lot size, Price, Subscription, allotment status and much more at IPO tank." />

</Helmet>

       <div style={{backgroundImage: "url('../best8.jfif')",backgroundSize: "cover", backgroundRepeat: "no-repeat", padding:"10px 0 10px 0"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
           <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
           {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        <Link to={`/closed-ipo`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>Closed IPO</p>
        </Link>
      </div>)}
      <br/>
    
      <h1 style={{marginTop:"-20px"}}>Closed IPO</h1>
      {/* <h3 style={{marginTop:"30px"}}>The financial markets are on the move</h3> */}
      <p style={{marginTop:"-10px", textAlign: "justify",fontSize:"15px"}}>"Closed IPO" refers to an Initial Public Offering that has concluded its subscription period, during which shares were offered to investors.</p>
      <p style={{marginTop:"0px", textAlign: "justify",fontSize:"15px"}}>List of all closed IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Review the performance and outcomes of recently closed IPOs.</p>
      </div>
      </div></div>
      <br/>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
     
     <div style={{padding:"15px 15px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
     <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px auto",
    flexWrap: "wrap", // Allow items to wrap to next line
    gap: "10px", // Gap between items
  }}
>
  <div
    style={{
      backgroundColor: activeTab === "All IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "All IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("All IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "All IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0, // Reset default margin
      }}
    >
      All IPO
    </p>
  </div>
  <div
    style={{
      backgroundColor: activeTab === "Mainboard IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "Mainboard IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("Mainboard IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "Mainboard IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0,
      }}
    >
      Mainboard IPO
    </p>
  </div>
  <div
    style={{
      backgroundColor: activeTab === "SME IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "SME IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("SME IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "SME IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0,
      }}
    >
      SME IPO
    </p>
  </div>
</div>

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          padding: "10px auto",
          // gap: "10px",
          // borderBottom:"2px solid #bdb4b8",
          // background: "green",
        }}
      >
        <div style={{
      backgroundColor:(activeTab === "All IPO") ? '#666' : '', padding:"0px 20px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "All IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("All IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "All IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "All IPO") ? '#ff7847' : '#666',
              color: (activeTab === "All IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            All IPO
          </p></div>
          <div style={{
      backgroundColor:(activeTab === "Mainboard IPO") ? '#666' : '', padding:"0px 25px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "Mainboard IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("Mainboard IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "Mainboard IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "Mainboard IPO") ? '#ff7847' : '#666',
              color: (activeTab === "Mainboard IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            Mainboard IPO
          </p>
          </div>
          <div style={{
      backgroundColor:(activeTab === "SME IPO") ? '#666' : '', padding:"0px 25px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "SME IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("SME IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "SME IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "SME IPO") ? '#ff7847' : '#666',
              color: (activeTab === "SME IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            SME IPO
          </p>
          </div>   </div> */}
        {/* <p
          onClick={() => handleIpoTypeChange("All IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
        //   style={{ 
        //   color: ipoType === "All IPO" ? "red" : "inherit"
          
      
        // }}
       
        >
          All IPO
        </p>
        <p
          onClick={() => handleIpoTypeChange("Mainboard IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
          // style={{ color: ipoType === "Main IPO" ? "red" : "inherit" }}
        >
          Mainboard IPO
        </p>
        <p
          onClick={() => handleIpoTypeChange("SME IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >
          SME IPO
        </p> */}
   

      


      {/* <br/> */}
        {/* <h2 style={{ textAlign: 'left' ,fontSize: "20px",fontWeight: "bold"}}>Closed IPO</h2> */}
        {filteredRows?.length > 0 ? (
           <div style={{maxWidth:"1200px",width:"100%",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text',marginTop:"15px" }}>
           {filteredRows.slice(0, visibleRows).map((row, index) => (
            <>
            <div key={index} style={{marginBottom:"15px", width:"100%",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
             {/* <Grid item xs={12} sm={6} md={12} key={index}> */}
               {/* <Card>
                 <CardContent> */}
                 <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
                  {/* <div style={{borderBottom:"1px solid #FF91A4" ,width:"100%",padding:"10px 0"}}>
  <Typography  component="div">
    <Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontSize:"15px", fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
      <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px", width: "50px" }} />
        {row[1]}
      </div>
      <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
      </div>
    </Link>
  </Typography>
                  </div> */}
                   <div style={{borderBottom:"1px solid #FF91A4" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
   <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px" }} />
   {row[1]}
 </div>
 {/* <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #FF91A4",borderRadius:"5px",backgroundColor:"#FF91A4",textDecoration:"none"}}>SME</label> :""}</div> */}
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/closed-ipo`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div>
</div>
</Link>
</Typography>
<div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}
<div style={{marginLeft:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])} 
      /></div></div>
             </div>
                  <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[6] !== '') ? (<strong>₹ {row[6]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}
     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20}/><div>Subs</div></div></label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( <strong>
      {row[9].split(' ')[0].replace(/[^\d,]/g, '')}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
      <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {/* {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>} */}
     {(row[14] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {/* {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}{JSON.parse(row[14])["Subscription (times)"]} times
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
                 </div>
                 {/* </CardContent>
               </Card> */}
             {/* </Grid> */}
             </div>
             </>
           ))}
            {filteredRows.length > 6 ? (
           <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => setShowAllRows(!showAllRows)}
              className="py-0 text-left pl-8">
             {/* // <button */}
             {/* //   onClick={() => setShowAllRows(!showAllRows)}
             //   className="py-0 text-left pl-8"
             // > */}
               {showAllRows ? (
                 <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}>
                   Show Less 
                   {/* <TiArrowSortedUp/> */}
                 
                 </div>
               ) : (
                 <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}>
                   View More
                   {/* <TiArrowSortedDown /> */}
                   {/* <GoChevronDown/> */}
                  
                 </div>
               )}
            </div> ):null}
           </div>
        // <TableContainer
        //   style={{ width: "100%", maxWidth: "1200px" }}
        //     component={Paper}
        // >
        //   <Table sx={{ minWidth: 350 }} aria-label="customized table">
           
        //       {filteredRows.length > initialRowCount && (
        //          <caption style={{ textAlign: "right", fontWeight: "bold" }} onClick={() => setShowAllRows(!showAllRows)}
        //          className="py-0 text-left pl-8">
              
        //           {showAllRows ? (
        //             <div className="flex " style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
                   
        //             onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        //             onMouseLeave={(e) => e.target.style.color = ''}>
        //               Show Less 
        //               <TiArrowSortedUp/>
                    
        //             </div>
        //           ) : (
        //             <div className="flex " style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
                   
        //             onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        //             onMouseLeave={(e) => e.target.style.color = ''}>
        //               View More
        //               <TiArrowSortedDown />
                   
                     
        //             </div>
        //           )}
        //       </caption>
        //       )}
           
        //     <TableHead>
        //       <TableRow>
        //         <StyledTableCell>Issuer Company</StyledTableCell>
        //         <StyledTableCell align="right">Open Date</StyledTableCell>
        //         <StyledTableCell align="right">Close Date</StyledTableCell>
        //         <StyledTableCell align="right">Listing Date</StyledTableCell>

        //         <StyledTableCell align="right">
        //           Issue Price (Rs)
        //         </StyledTableCell>
        //         <StyledTableCell align="right">
        //           Issue Size (Rs Cr.)
        //         </StyledTableCell>
        //         <StyledTableCell align="right">Lot Size</StyledTableCell>
        //         <StyledTableCell align="right">Exchange</StyledTableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {filteredRows.slice(0, visibleRows).map((row, index) => (
        //         <StyledTableRow key={index}>
                  
        //           <StyledTableCell component="th" scope="row">
        //             <Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        //                <div style={{ display: "flex", alignItems: "center" }}>
        //                 <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
        //                 {row[1]}
        //             </div>
        //             </Link>
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[3])} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[4])} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[5])}
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[6]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[7]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[8]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[9]} 
        //           </StyledTableCell>
        //         </StyledTableRow>
        //       ))}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
         ) : (
          <p style={{ textAlign: 'center'}}>No Closed {ipoType} available.</p>
      )}
      </div>
      
      <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
        <br/>

        {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{marginTop:"5px",width: "90%", maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent faqData={faqData} /></div></div> */}
        
        </div> 

        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{marginTop:"5px", width: "92%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="Closed IPO" /></div></div>
     
    </>
  );
}

  export default ClosedIPOs;