import React, { useEffect, useState } from 'react';
import '../BuybackDetails.css'; // Custom styling
import { Card, CardContent, Typography,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, useMediaQuery, Box, Divider } from '@mui/material';
import {  useTheme } from '@material-ui/core';
import { json, Link, useNavigate, useParams } from 'react-router-dom';
import { hostInfo, token  } from '../server';
import { Helmet } from 'react-helmet';
import { SlArrowRight } from "react-icons/sl";

const BuybackDetails = ({deviceInfo,navigation}) => {
  const [buybackDetails, setBuybackDetails] = useState([]);
  console.log(buybackDetails,"..................")

  const Params = useParams();
  
  const nevigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${hostInfo}/get_buyback_details`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
            'Content-Type': 'application/json', // Set the Content-Type if necessary
          },
          body: JSON.stringify({ ipo_route_name: Params["buyback-details"] })
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        console.log(data)
        setBuybackDetails(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        nevigate('/');
        // Handle the error as needed
      }
    };
  
    fetchData();

  }, [Params]);

  // const {
  //   about_section,
  //   additional_text,
  //   buyback_details,
  //   buyback_ratio,
  //   calculation_section,
  //   company_contact,
  //   financials,
  //   image_url,
  //   ipo_name,
  //   issue_timetable,
  //   objective,
  //   participating_section,
  //   registrar_contact,
  // } = data;

  const buybackDetailsObj =buybackDetails &&  buybackDetails?.buyback_details && JSON.parse(buybackDetails?.buyback_details);
  const buybackRatioObj = buybackDetails &&  buybackDetails?.buyback_ratio && JSON.parse(buybackDetails?.buyback_ratio);
  const calculationSectionObj = buybackDetails &&  buybackDetails?.calculation_section && JSON.parse(buybackDetails?.calculation_section);
  const investmentCalc = calculationSectionObj && calculationSectionObj["Calculation of Investment"];
  console.log(calculationSectionObj);
  const expectedProfit = calculationSectionObj && calculationSectionObj["Expected Profit from Buyback"];
  // console.log(expectedProfit);

  const issueTimetableObj = buybackDetails &&  buybackDetails?.issue_timetable && JSON.parse(buybackDetails?.issue_timetable);
  const companyContactObj = buybackDetails &&  buybackDetails?.company_contact && JSON.parse(buybackDetails?.company_contact);
  const participatingSectionObj = buybackDetails &&  buybackDetails?.participating_section && JSON.parse(buybackDetails?.participating_section);
  const registrarContactObj = buybackDetails &&  buybackDetails?.registrar_contact && JSON.parse(buybackDetails?.registrar_contact);
  const financialsObj = buybackDetails &&  buybackDetails?.financials && JSON.parse(buybackDetails?.financials);
  // console.log(financialsObj[0]);
  const financialsObjheaders = financialsObj && financialsObj[0];
  console.log(financialsObjheaders);
  // // Extract column headers dynamically
  const headers =financialsObjheaders &&  ["Period Ended", ...Object.keys(financialsObjheaders).filter(key => key !== "Period Ended")];
  console.log(headers);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  

  return (
<>
    {/* <Helmet>
    <title>{`${buybackDetails?.ipo_name} - Comprehensive Trading and Investment Services`}</title>
    <meta name="description" content={`${buybackDetails?.ipo_name}, a leading ${buybackDetails?.additional_text} in India, offers a wide range of trading and investment services including equity, commodities, futures, and options. With a robust platform and competitive brokerage plans, ${buybackDetails?.ipo_name} is a trusted choice for investors.`} />
    <meta name="keywords" content={`${buybackDetails?.ipo_name}, ${buybackDetails?.additional_text}, equity trading, commodity trading, futures trading, options trading, stock market, investment services, IPOtank, IPO tank`} />
 
<meta property="og:url" content={`https://www.ipotank.com/broker/${Params["buyback-details"]}`} />
<meta property="og:type" content="website" />
<meta property="og:title" content={`${buybackDetails?.ipo_name} - Comprehensive Trading and Investment Services`} />
<meta property="og:description" content={`${buybackDetails?.ipo_name}, a leading ${buybackDetails?.additional_text} in India, offers a wide range of trading and investment services including equity, commodities, futures, and options. With a robust platform and competitive brokerage plans, ${buybackDetails?.ipo_name} is a trusted choice for investors.`} />
  </Helmet> */}

  <Helmet>
      {/* Dynamic Title */}
      <title>{`${buybackDetails?.ipo_name} - Key Information and Investment Details`}</title>

      {/* Meta Description */}
      <meta 
        name="description" 
        content={`${buybackDetails?.ipo_name} is conducting a ${buybackDetails?.additional_text} buyback. Explore details including buyback price, company performance, and future prospects.`} 
      />

      {/* Meta Keywords */}
      <meta 
        name="keywords" 
        content={`${buybackDetails?.ipo_name}, ${buybackDetails?.additional_text} buyback, buyback offer, investment opportunities, share repurchase, IPOtank, IPO tank`} 
      />

      {/* Open Graph Meta Tags for Social Sharing */}
      <meta property="og:url" content={`https://www.ipotank.com/buyback/${Params["buyback-details"]}`} />
      <meta property="og:type" content="website" />
      <meta 
        property="og:title" 
        content={`${buybackDetails?.ipo_name} - Key Information and Investment Details`} 
      />
      <meta 
        property="og:description" 
        content={`${buybackDetails?.ipo_name} is conducting a ${buybackDetails?.additional_text} buyback. Explore details including buyback price, company performance, and future prospects.`} 
      />
    </Helmet>

  <div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }}
> 
<div style={{ width: '90%', maxWidth: '1200px' }}>
{(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"10px"}} >
  <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
  onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
    <p>
      Home
    </p>
  </Link>
  <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>

  <Link to={`/buybacks`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
  onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
    <p>Buyback</p>
  </Link>
  <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
  <Link to={`/buyback/${Params["buyback-details"]}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
  onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
    <p>{buybackDetails?.ipo_name?.split(' ').length > 2 
    ? buybackDetails?.ipo_name?.split(' ').slice(0, 2).join(' ') + '...' 
    : buybackDetails?.ipo_name}</p>
  </Link>
</div>)}

</div>
</div>



  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"5px",
      marginBottom:"30px",
      textAlign:"justify",
    }}
  >
       
  <div  style={{width: "90%",maxWidth:"1200px",minWidth:"100px",height:"auto" }}>


  {/* <div style={{ margin:"0px 0px 0px 0px",borderRadius: "10px",
boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
backgroundColor: "#f9f9f9",padding:"10px"}}> */}

 {/* <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"20px",alignItems:"center"}}><img src={brokerData?.Broker_Logo} alt="" style={{width:"100px",maxHeight:"100px"}}/><div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}><div><h2 style={{margin:"0px"}}>{brokerData?.Broker_Name}</h2><div>{brokerData?.Broker_Type}</div></div><a href={brokerData?.Open_Demat_Account_Url} target='blank' style={{textDecoration:"none"}}>Open Demat Account</a></div></div> */}

 <div className="header">
<img 
  src={buybackDetails?.image_url} 
  alt={`${buybackDetails?.ipo_name} Logo`} 
  // className="company-logo"
   style={{
                    height: '90px',
                    objectFit: 'contain',
                    width: '90px',
   }}
   loading="lazy"
/>
<div 
  className={`broker-card-content ${window.innerWidth <= 768 ? 'broker-card-content-column' : ''}`}
  style={{gap:"5px"}}
>
  <div style={{ flex: "1" }}> 
    <h3 style={{marginTop:"0",marginBottom:"0"}}>
    {buybackDetails?.ipo_short_name}
    </h3>
    <div className="broker-type" style={{fontSize:"13px",marginTop:"2px"}}>
      {buybackDetails?.ipo_name}
    </div>
    <div className="broker-type" style={{fontSize:"13px",marginTop:"0"}}>
      {buybackDetails?.additional_text}
    </div>
   
  </div>
  <div style={{display:"flex", flexWrap:"wrap", alignItems:"center",gap:"3px"}}>
    <div><strong style={{fontSize:"16px",color:"#00796b"}}>{buybackDetails?.buyback_price}</strong></div><div style={{fontSize:"16px",color:"#666"}}>/</div><div style={{fontSize:"13px",color:"#666"}}>Buyback price per share</div>
  </div>
</div>
</div>

{/* </div>  */}


   
 <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 0px 0px",}}>About {buybackDetails?.ipo_short_name}</h3>
 <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: buybackDetails?.about_section }} /></div>



     <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>Buyback Details</h3>
     <div className="brokerage-plans">
      <div className="plan-content" style={{paddingBottom:"0px"}}>
      <div className="plan-section-content">
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Security Name</span>
                  <span className="plan-item-value">
                    <span style={{ color: '#00796b'}}>{buybackDetailsObj && buybackDetailsObj["Security Name"]}</span>
                  </span>
      </div>
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Buyback Price</span>
                  <span className="plan-item-value">
                    <span style={{ color: '#00796b'}}>{buybackDetailsObj && buybackDetailsObj["Buyback Price"]} / Share</span>
                  </span>
      </div>
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Face Value</span>
                  <span className="plan-item-value">
                    <span style={{ color: '#00796b'}}>{buybackDetailsObj && buybackDetailsObj["Face Value"]} / Share</span>
                  </span>
      </div>
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Listing at Group</span>
                  <span className="plan-item-value">
                    <span style={{ color: '#00796b'}}>{buybackDetailsObj && buybackDetailsObj["Listing at Group"]}</span>
                  </span>
      </div>
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Lead Manager</span>
                  <span className="plan-item-value">
                    <span style={{ color: '#00796b'}}>{buybackDetailsObj && buybackDetailsObj["Lead Manager"]}</span>
                  </span>
      </div>
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Buyback Docs</span>
                  <span className="plan-item-value">
                  <a href={buybackDetailsObj && buybackDetailsObj["Buyback Docs"]} target="_blank" rel="noopener noreferrer"><span>Letter Of Offer</span></a>
                    
                  </span>
      </div>
      <div className="plan-item" style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}>
                  <span className="plan-item-key" style={{color:"#666"}}>Issue Size</span>
                  <span className="plan-item-value">
                    <span style={{ color: '#00796b'}}>{buybackDetailsObj && buybackDetailsObj["Issue Size"]}</span>
                  </span>
      </div>
       </div>
      </div>
    </div>

    {/* <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  > 
   <div style={{ width: '93%', maxWidth: '1200px' }}>
    <div className="buyback-container"> */}
      {/* Header Section */}
      {/* <div className="header">
        <img src={buybackDetails?.image_url} alt={buybackDetails?.ipo_name} className="company-logo" />
        <Typography variant="h4" className="company-title">{buybackDetails?.ipo_name}</Typography>
      </div> */}

      {/* About Section */}
      {/* <Card className="about-section">
        <CardContent>
          <Typography variant="h6">About the Company</Typography>
          <div dangerouslySetInnerHTML={{ __html: buybackDetails?.about_section }} />
        </CardContent>
      </Card> */}

      {/* Buyback Details */}
      {/* <Card className="buyback-details-section">
        <CardContent>
          <Typography variant="h6">Buyback Details</Typography>
          <Typography><b>Security Name:</b> {buybackDetailsObj && buybackDetailsObj["Security Name"]}</Typography>
          <Typography><b>Buyback Price:</b> {buybackDetailsObj && buybackDetailsObj["Buyback Price"]}</Typography>
          <Typography><b>Lead Manager:</b> {buybackDetailsObj && buybackDetailsObj["Lead Manager"]}</Typography>
          <Typography><b>Issue Size:</b> {buybackDetailsObj && buybackDetailsObj["Issue Size"]}</Typography>
          <a href={buybackDetailsObj && buybackDetailsObj["Buyback Docs"]} target="_blank" rel="noopener noreferrer">View Buyback Documents</a>
        </CardContent>
      </Card> */}

<h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 5px 0px"}}>Issue Timetable</h3>
      {/* <Typography variant="h6" style={{marginTop:"20px"}}>Issue Timetable</Typography> */}
      <TableContainer component={Paper} sx={{ maxWidth: '500px', overflowX: 'auto', marginTop: 2 }}>
      {/* <Typography variant="h5" align="center" gutterBottom>
        Issue Timetable
      </Typography> */}
      <Table aria-label="issue timetable">
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={2} sx={{  backgroundColor: '#00796b', color: 'white', fontSize: "16px" }}>
          {buybackDetails?.ipo_name}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {issueTimetableObj && Object.entries(issueTimetableObj).map(([event, date], index) => (
          <TableRow key={index}>
            <TableCell sx={{ fontWeight: 'bold', fontSize: "14px", backgroundColor: '#e0f2f1', width: "50%" }}>
              {event}
            </TableCell>
            <TableCell sx={{ fontSize: "14px", backgroundColor: '#ffffff', width: "50%" }}>
              {date}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      {/* <Table aria-label="issue timetable">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#00796b', color: 'white', fontSize:"15px" }}>
              Event
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#00796b', color: 'white', fontSize:"15px"}}>
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issueTimetableObj && Object.entries(issueTimetableObj).map(([event, date], index) => (
            <TableRow key={index}>
              <TableCell align="left">{event}</TableCell>
              <TableCell align="left">{date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </TableContainer>

    {/* <Typography variant="h6" style={{marginTop:"20px"}}>Issue Timetable</Typography> */}
    {/* <Paper sx={{ padding: 3, maxWidth: '100%', marginTop: 2 }}> */}
      {/* <Typography variant="h5" align="center" gutterBottom>
        Issue Timetable
      </Typography> */}

      {/* <Grid container spacing={2}>
        {issueTimetableObj && Object.entries(issueTimetableObj).map(([event, date], index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card sx={{ minWidth: 275, margin: 1 }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {event}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
    {/* </Paper> */}

      {/* Buyback Ratio */}
      {/* <Card className="buyback-ratio-section">
        <CardContent> */}
      
        {buybackRatioObj && (<>
        <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 0px 0px"}}>Buyback Ratio</h3>
        {/* <Typography variant="h6" style={{margin:"20px 0px 15px 0px"}}>Buyback Ratio</Typography> */}
          <Table sx={{ minWidth: 200 }}>
            <TableHead style={{fontWeight:"bold"}} >
              <TableRow>
                <TableCell style={{fontWeight:"bold",fontSize:"15px",color:"#00796b"}}>Category</TableCell>
                <TableCell style={{fontWeight:"bold",fontSize:"15px",color:"#00796b"}}>Entitlement Ratio</TableCell>
                <TableCell style={{fontWeight:"bold",fontSize:"15px",color:"#00796b"}}>Shares Offered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buybackRatioObj?.map((ratio, index) => (
                <TableRow key={index}>
                  <TableCell style={{color:"#666"}}>{ratio.Category}</TableCell>
                  <TableCell>{ratio["Entitlement Ratio of Buyback"]}</TableCell>
                  <TableCell>{ratio["Shares Offered"]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{padding:"10px 0px 0px 0px",fontSize:"13px",color:"#666"}}><i>* Entitlement Ratio (x/y) = x Equity Shares out of every y Fully paid-up Equity Shares held on the Record Date.	</i></div>
          </>)}
          
        {/* </CardContent>
      </Card> */}

      {/* Financials Section */}
      {/* <Card className="financials-section"> */}
        {/* <CardContent> */}
        {financialsObj && headers && (<> 
        {/* <Typography variant="h6" style={{marginTop:"20px"}}>Company Financials</Typography> */}
        <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 5px 0px"}}>Company Financials</h3>
          <TableContainer component={Paper} sx={{ maxWidth: '800px', overflowX: 'auto', marginTop: 2 }}>
     
      <Table aria-label="financials table">
        <TableHead>
          <TableRow>
            {headers?.map((header, index) => (
              <TableCell key={index} align="left" sx={{ fontWeight:"bold", backgroundColor: '#00796b', color: 'white' }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {financialsObj?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headers.map((header, colIndex) => (
                <TableCell key={colIndex} align="left"  sx={{ color: colIndex === 0 ? '#00796b' : 'black' }}>
                  {row[header]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <caption style={{textAlign:"right"}}>All values are in ₹ Cr</caption>
      </Table>
    </TableContainer></>)}
         
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableCell>Period Ended</TableCell>
                {Object.keys(financials[0]).filter((key) => key !== 'Period Ended').map((year, index) => (
                  <TableCell key={index}>{year}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {financials.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row["Period Ended"]}</TableCell>
                  <TableCell>{row["31-Mar-23"]}</TableCell>
                  <TableCell>{row["31-Mar-22"]}</TableCell>
                  <TableCell>{row["31-Mar-21"]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
        {/* </CardContent> */}
      {/* </Card> */}

     
  

      {/* Participation Section */}
      {participatingSectionObj && ( <>
        {/* // <Card className="participation-section"> */}
        {/* <CardContent> */}
          {/* <Typography variant="h6">{participatingSectionObj && participatingSectionObj.heading}</Typography> */}
          <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"30px 0px 0px 0px"}}>{participatingSectionObj && participatingSectionObj.heading}</h3>
          <ul style={{listStyle:"none",paddingLeft:"0px"}}>
            {participatingSectionObj && participatingSectionObj.steps.map((step, index) => (
              <li key={index} style={{padding:"5px 0px"}}>{step}</li>
            ))}
          </ul>
         
      {/* //   </CardContent> */}
      {/* // </Card> */} </>
) }


{/* <Box sx={{ padding: 4 }}> */}
     

      {/* Calculation of Investment Section */}
    
    {/* <Card
  sx={{
    mb: 4,
    backgroundColor: '#f9f9f9',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: 2,
  }}
>
  <CardContent> */}
    {/* <Typography
      variant="h5"
      gutterBottom
      sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        mb: 3,
        color: '#1976d2',
      }}
    >
      Calculation of Investment
    </Typography>
    <Divider sx={{ mb: 2, backgroundColor: '#1976d2' }} /> */}
   <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 20px 0px"}}>Calculation of Investment in {buybackDetails?.ipo_short_name}</h3>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 250 }} aria-label="calculation table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: '#424242' }}>
              Description
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#424242' }}>
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calculationSectionObj && Object.entries(calculationSectionObj["Calculation of Investment"]).map(([key, value], index) => (
            <TableRow
              key={index}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                '&:nth-of-type(even)': { backgroundColor: '#ffffff' },
              }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 500 }}>
                {key}
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: "#00796b"}}>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  {/* </CardContent>
</Card> */}
   <ul style={{listStyle:"none",paddingLeft:"0px"}}>
            {buybackDetails?.p_tags_with_i  && JSON.parse(buybackDetails?.p_tags_with_i).map((step, index) => (
              <li key={index} style={{padding:"3px 0px",fontSize:"13px",color:"#666"}}><i>{step}</i></li>
            ))}
   </ul>

      {/* Expected Profit from Buyback Section */}
      {/* <Card>
        <CardContent> */}
          <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 20px 0px"}}>Expected Profit from the {buybackDetails?.ipo_short_name} on the bases of acceptance Ratio:</h3>
          {/* <Typography variant="h5" gutterBottom>
            Expected Profit from Buyback
          </Typography> */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{backgroundColor:"#f5f5f5"}}>
                  <TableCell style={{fontWeight:"bold"}}>Acceptance Ratio</TableCell>
                  {["33%", "50%", "75%", "100%"].map((ratio) => (
                    <TableCell key={ratio} align="center" style={{fontWeight:"bold"}}>{ratio}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {calculationSectionObj && calculationSectionObj["Expected Profit from Buyback"].map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{color:"black"}}>{row["Acceptance Ratio"]}</TableCell>
                    {["33%", "50%", "75%", "100%"].map((ratio) => (
                      <TableCell key={ratio} align="center">{row[ratio]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
         
        {/* </CardContent>
      </Card> */}
    {/* </Box> */}

    {buybackDetails?.objective && buybackDetails.objective.trim() !== "<p></p>" && (<><h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 0px 0px",}}>{buybackDetails?.ipo_short_name} - Necessity of the Objective</h3>
    <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: buybackDetails?.objective }} /></div></>)}

    <Grid container spacing={isMobile ? 0 : 2}>

{/* Company Contact Section */}
{companyContactObj && (
  <Grid item xs={12} md={6}>
    <Card 
      className="company-contact-section"
      sx={{
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        backgroundColor: '#fafafa',
        // padding: '5px 5px 5px 5px',
        border: '1px solid #ddd',
      }}
    >
      <CardContent style={{padding: '20px'}}>
        <Typography  gutterBottom  sx={{ fontWeight: 'bold',color:"#00796b",borderBottom: "1px solid #dddcdd" }}>
          Company Contact Information
        </Typography>
        <Typography sx={{ margin: "10px 0px 10px 0px", fontSize: '1rem' }}>
          <b>{companyContactObj["Company Name"]}</b>
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px" }}>
          {companyContactObj.Address}
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word'  }}>
          <b>Email:</b> {companyContactObj.Email}
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px" }}>
          <b>Phone:</b> {companyContactObj.Phone}
        </Typography>
        <Typography style={{fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word' }}>
          <b>Website:</b> 
          <a href={companyContactObj.Website} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2', textDecoration: 'none',marginLeft:"5px" }}>
            {companyContactObj.Website}
          </a>
        </Typography>
      </CardContent>
    </Card>
  </Grid>
)}

{/* Registrar Contact Section */}
{registrarContactObj && Object.keys(registrarContactObj).length !== 0 && (
  <Grid item xs={12} md={6}>
    <Card 
      className="registrar-contact-section"
      sx={{
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        backgroundColor: '#fafafa',
        // padding: '20px',
        border: '1px solid #ddd',
      }}
    >
      <CardContent style={{padding: '20px'}}>
        <Typography  gutterBottom  sx={{ fontWeight: 'bold',color:"#00796b",borderBottom: "1px solid #dddcdd" }}>
          Registrar Contact Information
        </Typography>
        <Typography sx={{ margin: "10px 0px 10px 0px", fontSize: '1rem' }}>
          <b>{registrarContactObj["Registrar Name"]}</b>
        </Typography>
        <Typography sx={{  marginBottom: '5px',fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word' }}>
          <b>Email:</b> {registrarContactObj.Email}
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px" }}>
          <b>Phone:</b> {registrarContactObj.Phone}
        </Typography>
        <Typography sx={{ fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word'  }}>
          <b>Website:</b> 
          <a href={registrarContactObj.Website} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2', textDecoration: 'none',marginLeft:"5px" }}>
            {registrarContactObj.Website}
          </a>
        </Typography>
      </CardContent>
    </Card>
  </Grid>
)}

</Grid>



      {/* <Grid container spacing={isMobile ? 1 : 3}>
     
      {companyContactObj && ( <Grid item xs={12} md={6}>
        <Card className="company-contact-section">
          <CardContent>
            <Typography variant="h6">Company Contact</Typography>
            <Typography style={{ margin: "10px 0px 5px 0px" }}>
              <b>{companyContactObj["Company Name"]}</b>
            </Typography>
            <Typography><b>Address:</b> {companyContactObj.Address}</Typography>
            <Typography><b>Email:</b> {companyContactObj.Email}</Typography>
            <Typography><b>Phone:</b> {companyContactObj.Phone}</Typography>
            <Typography><b>Website:</b> <a href={companyContactObj.Website} target="_blank" rel="noopener noreferrer">{companyContactObj.Website}</a></Typography>
          </CardContent>
        </Card>
      </Grid>)}

    
{registrarContactObj && registrarContactObj && ( 
 
      <Grid item xs={12} md={6}>
        <Card className="registrar-contact-section">
          <CardContent>
            <Typography variant="h6">Registrar Contact</Typography>
            <Typography style={{ margin: "10px 0px 5px 0px" }}>
              <b>{registrarContactObj["Registrar Name"]}</b>
            </Typography>
            <Typography><b>Email:</b> {registrarContactObj.Email}</Typography>
            <Typography><b>Phone:</b> {registrarContactObj.Phone}</Typography>
            <Typography><b>Website:</b> <a href={registrarContactObj.Website} target="_blank" rel="noopener noreferrer">{registrarContactObj.Website}</a></Typography>
          </CardContent>
        </Card>
      </Grid>)}
     
    </Grid> */}

      {/* Company Contact */}
      {/* <Card className="company-contact-section">
        <CardContent>
          <Typography variant="h6">Company Contact</Typography>
          <Typography><b>Company Name:</b> {companyContactObj["Company Name"]}</Typography>
          <Typography><b>Address:</b> {companyContactObj.Address}</Typography>
          <Typography><b>Email:</b> {companyContactObj.Email}</Typography>
          <Typography><b>Phone:</b> {companyContactObj.Phone}</Typography>
          <Typography><b>Website:</b> <a href={companyContactObj.Website} target="_blank" rel="noopener noreferrer">{companyContactObj.Website}</a></Typography>
        </CardContent>
      </Card> */}

       {/* Registrar Contact Section */}
       {/* <Card className="registrar-contact-section">
        <CardContent>
          <Typography variant="h6">Registrar Contact</Typography>
          <Typography><b>Name:</b> {registrarContactObj["Registrar Name"]}</Typography>
          <Typography><b>Email:</b> {registrarContactObj.Email}</Typography>
          <Typography><b>Phone:</b> {registrarContactObj.Phone}</Typography>
          <Typography><b>Website:</b> <a href={registrarContactObj.Website} target="_blank" rel="noopener noreferrer">{registrarContactObj.Website}</a></Typography>
        </CardContent>
      </Card> */}
    {/* </div> </div>
  </div> */}


  </div> </div>
  </>
  );
};

export default BuybackDetails;
