import React from 'react';
import { Helmet } from 'react-helmet';

const IPOInvestorCategories = () => {
  const styles = {
    container: {
      marginTop:"100px",
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      padding: '20px',
      color: '#333',
      fontSize:"15px",
    },
    header1: {
      fontSize: '2em',
      fontWeight: 'bold',
      marginBottom: '10px',
      paddingBottom: '10px',
      borderBottom:"1px solid #ccc"
    },
    header: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    sectionHeader: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    section: {
      marginBottom: '20px',
    },
    list: {
      paddingLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    contact: {
      marginTop: '30px',
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    emailLink: {
      color: '#007BFF',
      textDecoration: 'none',
    },
  };

  return (
    <>
    <Helmet>
   <title>IPO Investor Categories - IPOtank</title>
   <meta name="description" content="Learn about different IPO investor categories, including retail investors, institutional investors, and high-net-worth individuals. Understand the importance and roles of each category in the IPO process at IPOtank." />
   <meta name="keywords" content="IPO Investor Categories, retail investors, institutional investors, HNI, IPO process, investor types, IPOtank, IPO tank" />
   
   <meta property="og:url" content="https://www.ipotank.com/ipo-investor-categories" />
   <meta property="og:type" content="website" />
   <meta property="og:title" content="IPO Investor Categories - IPOtank" />
   <meta property="og:description" content="Explore different categories of IPO investors, from retail to institutional investors, and understand how each plays a role in the IPO process at IPOtank." />
</Helmet>


  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"30px",
      textAlign:"justify",
    }}
  >
       <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    {/* <div style={styles.container}> */}
      <div style={styles.header1} id="h-privacy-policy-for-IPOtank">
      IPO Investor Categories
      </div>
      {/* <h3 style={{ color:"#666",marginTop:"45px" }}>IPO Investor Categories</h3> */}
        <ul style={{fontSize:"15px",listStylePosition: "outside",marginLeft:"-20px"}}>
            <li>
                <strong>Qualified Institutional Buyers (QIB)</strong>
                <p>Financial Institutions, Banks, FIIs, and Mutual Funds registered with SEBI are called QIBs. In most cases, QIBs represent small investors who invest through mutual funds, ULIP schemes of insurance companies, and pension schemes.</p>
            </li>
            <li>
                <strong>Non-Institutional Investors(NII)</strong>
                <p>Retail Individual Investors (HNI), NRIs, Companies, Trusts, etc who bid for shares worth more than Rs 2 lakhs are known as Non-institutional bidders (NII). Unlike QIB bidders, they do not need SEBI registration.</p>
                <p>NII category has two subcategories:</p>
                <ol style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                    <li>
                        <strong> sNII (bids below Rs 10L)</strong>
                        <p>The Small NII category is for NII investors who bid for shares between Rs 2 lakhs to Rs 10 lakhs. The 1/3 of NII category shares are reserved for the Small NII sub-category. This subcategory is also known as Small HNI (sHNI).</p>
                    </li>
                    <li>
                        <strong> bNII (bids above Rs 10L)</strong>
                        <p>The Big NII category is for NII investors who bid for shares worth more than Rs 10 Lakhs. The 2/3 of NII category shares are reserved for the Big NII subcategory. This subcategory is also known as Big HNI (bHNI).</p>
                    </li>
                </ol>
            </li>
            <li>
                <strong>Retail Individual Investors(RII)</strong>
                <p>The retail individual investor or NRIs who apply up to Rs 2 lakhs in an IPO are considered as RII reserved category.</p>
            </li>
            <li>
                <strong>Employee (EMP)</strong>
                <p>A category of eligible employees who have a reserved quota in the IPO.</p>
            </li>
            <li>
                <strong>Others</strong>
                <p>A category of eligible shareholders or other investors who have a reserved quota in the IPO.</p>
            </li>
        </ul>
        <h3 itemprop="name" style={{fontSize:"17px",color:"black",marginTop:"35px",borderBottom:"1px solid #ccc",paddingBottom:"10px"}}>What is the difference between RII, NII, QIB and Anchor Investor?</h3>
        <div itemprop="text" style={{fontSize:"15px"}}>
            <p>Investors can apply for shares in one of the following categories:</p>
            <ol style={{listStylePosition: "outside",marginLeft:"-20px"}}>
                <li>
                <strong>Retail Individual Investor (RII)</strong>
                    <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                        <li style={{margin:"10px 0px"}}>Resident Indian Individuals, NRIs and HUFs who apply for less than Rs 2 lakhs in an IPO under RII category.</li>
                        <li style={{margin:"10px 0px"}}>Not less than 35% of the Offer is reserved for RII category.</li>
                        <li style={{margin:"10px 0px"}}>NRI or HUF who applying in an IPO with less than Rs 2,00,000 can apply in RII category.</li>
                        <li style={{margin:"10px 0px"}}>RII category allows bid at cut-off price.</li>
                        <li><strong>Allotment Basis</strong>
                            <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in RII Category, full allotment to all applicants.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in this category - The allotment is taken place via lottery if the retail portion is oversubscribed, and applicants will receive at least one lot regardless of how many lots they have applied for.
                                    <p>For example, if the IPO subscribed 2 times (application-wise) in the retail category, 1 out of 2 applicants will get 1 lot irrespective of how many shares they applied for.</p>
                                    <p>If investor A applied for Rs 2 lakhs (15 lots), investor B applied for 1 lot and investor C applied for Rs 1 lakh (7 lots) in an IPO at a cut-off price. If IPO is subscribed 3 times (application-wise) in the Retail Category, the allotment will be done through the lottery and only 1 of 3 applicants will get 1 lot allocated. It doesn't matter how many shares they have applied.</p>
                                </li>
                            </ul>
                        </li>
                        <li style={{margin:"10px 0px"}}>Retail and non-institutional bidders are permitted to withdraw their bids until the day of allotment.</li>
                        <li style={{margin:"10px 0px"}}>Note:
                            <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                                <li style={{margin:"10px 0px"}}>Always apply at cut-off price in this category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO oversubscribe, apply only 1 lot per IPO application.</li>
                                <li style={{margin:"10px 0px"}}>To maximize the allotment, apply though multiple accounts on your family members name.</li>
                            </ul>
                        </li>
                    </ul>
                </li><br/>
                <li>
                <strong>Non-institutional bidders (NII)</strong>
                    <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                        <li style={{margin:"10px 0px"}}>Resident Indian individuals, NRIs, HUFs, Companies, Corporate Bodies, Scientific Institutions, Societies, and Trusts who apply for more than Rs 2 lakhs of IPO shares fall under the NII category.</li>
                        <li style={{margin:"10px 0px"}}>Not less than 15% of the Offer is reserved for the NII category.</li>
                        <li style={{margin:"10px 0px"}}><strong>A high Net-worth Individual (HNI)</strong> who applies for over Rs 2 Lakhs in an IPO falls under this category.</li>
                        <li style={{margin:"10px 0px"}}>Non-institutional bidders are not permitted to withdraw their bids once placed. They could modify the bid price.</li>
                        <li style={{margin:"10px 0px"}}>NIIs are not eligible to bid at the cut-off price.</li>
                        <li style={{margin:"10px 0px"}}>NII need not register with SEBI.</li>
                        <li>
                            <strong>NII Sub-categories</strong>
                            <p>NII category has two subcategories:</p>
                            <ol style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                                <li><strong>sNII or sHNI (bids below Rs 10L)</strong>
                                    <p>The Small NII category is for NII investors who bid for shares between Rs 2 lakhs to Rs 10 lakhs. The 1/3 of NII category shares are reserved for the Small NII sub-category. This subcategory is also known as Small HNI (sHNI).</p></li>
                                <li><strong>bNII or bHNI (bids above Rs 10L)</strong>
                                    <p>The Big NII category is for NII investors who bid for shares worth more than Rs 10 Lakhs. The 2/3 of NII category shares are reserved for the Big NII subcategory. This subcategory is also known as Big HNI (bHNI).</p></li>
                            </ol>
                        </li>
                        <li><strong>sNII or sHNI Allotment Basis</strong>
                            <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in the sNII sub-category, full allotment to all applicants in the sNII category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in the sNII sub-category, the allotment to each investor shall<strong> not be less than the minimum application size (~Rs 2 Lakhs)</strong>, subject to the availability of Equity Shares in the sNII Portion.</li>
                                <li style={{margin:"10px 0px"}}>This is similar to allotment in the RII category but instead of 1 lot, the sNII investor gets shares worth of minimum application size which is just above Rs 2 lakhs.</li>
                                <li style={{margin:"10px 0px"}}>Example: if the IPO subscribed 2 times (application-wise) in the sNII sub-category, 1 out of 2 applicants will get the minimum sHNI application (worth around Rs 2 Lakhs) irrespective of how many shares they applied for.</li>
                            </ul>
                        </li>
                        <li><strong>bNII or bHNI Allotment Basis</strong>
                            <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                                <li style={{margin:"10px 0px"}}>If IPO doesn't get over-subscribed in the bNII sub-category, full allotment to all applicants in the bNII category.</li>
                                <li style={{margin:"10px 0px"}}>If IPO is oversubscribed in the bNII sub-category, the allotment to each investor shall<strong> not be less than the minimum sNII application size (~Rs 2 Lakhs)</strong>, subject to the availability of Equity Shares in the bNII Portion.</li>
                                <li style={{margin:"10px 0px"}}>This is similar to allotment in the RII category but instead of 1 lot, the bNII investor gets shares worth of minimum application size which is just around Rs 2 lakhs.</li>
                                <li style={{margin:"10px 0px"}}>Even though the investment limit for Big HNI is more than or equal to Rs 10 lakhs, the investors will receive shares worth Rs 2 lakhs on a lottery basis (same as for Small HNI).</li>
                            </ul>
                        </li>
                    </ul>
                </li><br/>
                <li>
                <strong>Qualified Institutional Bidders (QIB's)</strong>
                    <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                        <li style={{margin:"10px 0px"}}>Public financial institutions, commercial banks, mutual funds and Foreign Portfolio Investors etc can apply in QIB category. SEBI registration is required for institutions to apply under this category.</li>
                        <li style={{margin:"10px 0px"}}>50% of the Offer Size is reserved for QIB's</li>
                        <li style={{margin:"10px 0px"}}>Allotment Basis - Proportionate.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are mostly representatives of small investors who invest through mutual funds, ULIP schemes of insurance companies and pension schemes.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are prohibited by SEBI guidelines to withdraw their bids after the close of the IPOs.</li>
                        <li style={{margin:"10px 0px"}}>QIB's are not eligible to bid at cut-off price.</li>
                    </ul>
                </li><br/>
                <li>
                <strong>Anchor Investor</strong>
                    <ul style={{listStylePosition: "outside",marginLeft:"-10px"}}>
                        <li style={{margin:"10px 0px"}}>An anchor investor in a public issue refers to a qualified institutional buyer (QIB) making an application for a value of Rs 10 crores or more through the book-building process. An anchor investor can attract investors to public offers before they hit the market to boost their confidence.</li>
                        <li style={{margin:"10px 0px"}}>Up to 60% of the QIB Category can be allocated to Anchor Investors;</li>
                        <li style={{margin:"10px 0px"}}>Anchor Investor Offer Price is decided separately.</li>
                        <li style={{margin:"10px 0px"}}>Anchor investor's has different Anchor Investor Bid/Offer Period.</li>
                        <li style={{margin:"10px 0px"}}>The minimum application size for each anchor investor should be Rs 10 crores. No merchant banker, promoter or their relatives can apply for shares under the anchor investor category. In offers of size less than Rs 250 crores, there can be a maximum of 15 anchor investors, but in those over Rs 250 crores, SEBI recently removed the cap on the number of anchor investors.</li>
                        <li style={{margin:"10px 0px"}}>Anchor investor's are not eligible to bid at cut-off price.</li>
                    </ul>
                </li>
            </ol>
        </div>




    </div></div>



        
   
    
    </>
  );
};

export default IPOInvestorCategories;
