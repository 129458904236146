import React, { useState } from 'react';
import '../loginsignup.css';
import { Helmet } from 'react-helmet';
import { hostInfo , token } from '../server';

const ContactUs = () => {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const styles = {
    container: {
        display: "flex",
        marginBottom: "20px",
        justifyContent: "center",
        alignItems: "center",
        
        // marginTop: "75px",
        // border:"1px solid #ccc",
        // borderRadius:"15px"
      },
     
      row: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth:"350px",
        // minWidth:"290px",
        width:"90%"
      },
      formWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        border:"1px solid #ccc",
        // borderRadius:"10px",
        padding:"20px 40px",
       
        // maxWidth:"400px",
        width:"100%",
        borderRadius: "1.5rem",
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)"
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        width:"100%",
        // margin:"0px 20px",
      },
      title: {
        textAlign: 'center',
        margin: "20px 0px",
        fontSize: '1.2rem',
        fontWeight: 'bold',
      },
      inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
        // maxWidth:"380px",
        width:"100%"
      },
      icon: {
        marginRight: '0.5rem',
      },
      input: {
        padding: '0.5rem',
        fontSize: '1rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        // maxWidth:"400px",
        // width:"100%"
        // appearance: 'textfield',
        // MozAppearance: 'textfield',
        // WebkitAppearance: 'none',
      },
      errorText: {
        marginLeft: '0.5rem',
        fontSize: '12px',
        color: 'red',
      },
      button: {
        cursor: 'pointer',
        textDecoration: 'none',
        marginBottom:"1.5rem",
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        border: isHovered ? '1px solid #007BFF' :'1px solid #589ce5',
        backgroundColor: isHovered ? '#007BFF' : '#589ce5',
        // background: "#007BFF",
        fontSize: '1rem',
        color: 'white',
        // maxWidth:"300px",
        width:"100%"
      },
      header: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBottom: '10px',
        paddingBottom: '10px',
        borderBottom:"1px solid #ccc"
      },
      subHeader: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px',
      },
      section: {
        marginBottom: '20px',
      },
      list: {
        paddingLeft: '20px',
      },
      listItem: {
        marginBottom: '10px',
      },
      contact: {
        marginTop: '30px',
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginBottom: '10px',
      },
      emailLink: {
        color: '#007BFF',
        textDecoration: 'none',
      },
    socialMedia: {
      marginTop: '20px',
    },
    socialMediaLink: {
      color: '#007BFF',
      textDecoration: 'none',
      display: 'block',
      marginBottom: '5px',
    },
    address: {
      marginTop: '20px',
    },
    businessHours: {
      marginTop: '20px',
      marginBottom: '10px',
    },
  };

  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     if (validate()) {
//       // Handle contact form submission
//     }
//   };

//   const validate = () => {
//     let errors = {};
//     let isValid = true;

//     if (!inputs.name) {
//       errors.name = 'Name is required';
//       isValid = false;
//     }
//     if (!inputs.email) {
//       errors.email = 'Email is required';
//       isValid = false;
//     }
//     if (!/^\d{10}$/.test(inputs.phone)) {
//       errors.phone = 'Phone is required';
//       isValid = false;
//     }

//     setErrors(errors);
//     return isValid;
//   };

  const handleContact = () => {
    fetch(`${hostInfo}/contactUs`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      },
      body: JSON.stringify(inputs),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Failed to contact');
        }
      })
      .then((data) => {
        if (data.status === 1) {
          alert(data.message);
          setInputs({
            name: '',
            email: '',
            phone: '',
            message: '',
            // status: '',
          });
        } else {
          alert(data.message);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      handleContact();
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!inputs.name) {
      errors.name = 'Name is required';
      isValid = false;
    }
    if (!inputs.email) {
      errors.email = 'Email is required';
      isValid = false;
    }
    if (!/^\d{10}$/.test(inputs.phone)) {
      errors.phone = 'Phone is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
   <Helmet>
   <title>Contact Us IPOtank</title>
   <meta name="description" content="Contact IPO tank for inquiries, feedback, or partnership opportunities related to IPOs and stock market investments. We’re here to assist you!" />
   <meta name="keywords" content="Contact IPOtank, IPO inquiries, IPOtank contact, partnership opportunities, IPOtank, IPO tank" />


   <meta property="og:url" content="https://www.ipotank.com/ContactUs" /> <meta property="og:type" content="website" /> <meta property="og:title" content="Contact Us IPOtank" /> <meta property="og:description" content="Contact IPO tank for inquiries, feedback, or partnership opportunities related to IPOs and stock market investments. We’re here to assist you!" />

</Helmet>

  
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"30px",
      textAlign:"justify",
    }}
  >
       <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    {/* <div style={styles.container}> */}
      <div style={styles.header}>Contact Us</div>
      <div style={styles.section}>We’d Love to Hear From You!</div>
      <div style={styles.section}>
        Whether you have questions, feedback, or suggestions, we’re here to help. Reach out to us using any of the methods below, and we’ll get back to you as soon as possible.
      </div>

      <div style={styles.subHeader}>General Inquiries, Support, Media Inquiries, Feedback</div>
      <div style={styles.section}>
      For all inquiries, including general questions, technical support, media inquiries, and feedback, please email us at <a href="mailto:ipotank@gmail.com" style={styles.emailLink}>ipotank@gmail.com</a> or contact us by filling out the form. Our dedicated team is ready to assist you with any queries you may have about IPO Tank. Whether you need help navigating our website, have press-related questions, or want to share your thoughts on how we can improve, we're here to listen and respond promptly. Your feedback is valuable to us as we strive to enhance our services and your experience with IPO Tank.
      </div>

     

{/* <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
    //   minHeight: '100vh', 
    //   backgroundColor: '#f0f2f5', 
      fontFamily: 'Arial, sans-serif' ,
      marginBottom:"20px"
    }}>
      <div  style={{ 
        backgroundColor: '#fff', 
        padding: '20px', 
        borderRadius: '10px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
        width: '100%', 
        maxWidth: '400px' , 
        //  display:"flex",
        // flexDirection:"column",
        // // justifyContent:"center",
        // alignItems:"center",
        // // textAlign:"center",
      }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Contact Us</h2>
        
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="name" style={{ 
            display: 'block', 
            marginBottom: '5px', 
            color: '#333' 
          }}>Name</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={inputs.name} 
            onChange={handleChange} 
            style={{ 
              width: '94%', 
              padding: '10px',
              // marginRight:"10px" ,
              borderRadius: '5px', 
              border: '1px solid #ccc' 
            }} 
            required 
          />
            {errors.name && (
                <span style={styles.errorText}>{errors.name}</span>
              )}
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="email" style={{ 
            display: 'block', 
            marginBottom: '5px', 
            color: '#333' 
          }}>Email</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={inputs.email} 
            onChange={handleChange} 
            style={{ 
              width: '94%',  
                padding: '10px', 
              borderRadius: '5px', 
              border: '1px solid #ccc' 
            }} 
            required 
          />
            {errors.email && (
                <span style={styles.errorText}>{errors.email}</span>
              )}
        </div>
        
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="mobileNumber" style={{ 
            display: 'block', 
            marginBottom: '5px', 
            color: '#333' 
          }}>Mobile Number</label>
          <input 
            type="number" 
            id="mobileNumber" 
            name="mobileNumber" 
            value={inputs.mobileNumber} 
            onChange={handleChange} 
            style={{ 
              width: '94%', 
                padding: '10px', 
              borderRadius: '5px', 
              border: '1px solid #ccc' 
            }} 
            required 
          />
            {errors.phone && (
                <span style={styles.errorText}>{errors.phone}</span>
              )}
        </div>
        
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="message" style={{ 
            display: 'block', 
            marginBottom: '5px', 
            color: '#333' 
          }}>Message</label>
          <textarea 
            id="message" 
            name="message" 
            value={inputs.message} 
            onChange={handleChange} 
            rows="4" 
            style={{ 
              width: '94%', 
              // maxWidth:"auto",
              padding: '10px',   
              borderRadius: '5px', 
              border: '1px solid #ccc' 
            }} 
            required 
          />
           
        </div>
        
        <button type="submit" onSubmit={handleSubmit} style={{ 
          width: '100%', 
        //   display:"flex",
        //   justifyContent:"center",
        //   alignItems:"center",
          textAlign:"center",
          padding: '10px', 
          backgroundColor: '#007bff', 
          color: '#fff', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer', 
          fontSize: '16px' 
        }}>Contact</button>
      </div>
    </div> */}

<div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.formWrapper}>
          <div style={styles.form}>
            <div style={styles.title}>Contact Us Form</div>
            <div style={styles.inputGroup}>
              {/* <i className="bx bxs-user" style={styles.icon}></i> */}
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={inputs.name}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.name && (
                <span style={styles.errorText}>{errors.name}</span>
              )}
            </div>
            <div style={styles.inputGroup}>
              {/* <i className="bx bxs-lock-alt" style={styles.icon}></i> */}
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={inputs.email}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.email && (
                <span style={styles.errorText}>{errors.email}</span>
              )}
            </div>
            <div style={styles.inputGroup}>
              {/* <i className="bx bxs-user" style={styles.icon}></i> */}
              <input
                type="number"
                name="phone"
                onKeyDown={(event) => {
                  // Prevent invalid keys like 'e', '-', '+', arrow keys
                  if (
                    event.key === 'ArrowUp' ||
                    event.key === 'ArrowDown' ||
                    event.key === '.' ||
                    event.key === '-' ||
                    event.key === '+'
                  ) {
                    event.preventDefault();
                  }
                }}
                onWheel={(event) => {
                  event.currentTarget.blur(); // Ensure input field maintains focus
                }}
                onKeyPress={(event) => {
                  if (
                    event.ctrlKey &&
                    (event.code === 'ArrowUp' || event.code === 'ArrowDown')
                  ) {
                    event.preventDefault();
                  }
                }}
                placeholder="Mobile Number"
                value={inputs.phone}
                onChange={handleChange}
                style={styles.input}
              />
              {errors.phone && (
                <span style={styles.errorText}>{errors.phone}</span>
              )}
            </div>
            <div style={styles.inputGroup}>
              {/* <i className="bx bxs-user" style={styles.icon}></i> */}
              <textarea
                type="text"
                name="message"
                placeholder="Message"
                value={inputs.message}
                onChange={handleChange}
                style={styles.input}
              />
            </div>

            <button onClick={handleSubmit}   onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave} style={styles.button}>
              Contact
            </button>
          </div>
        </div>
      </div>
    </div>

      <div style={styles.section}>
        We aim to respond to all inquiries within 1-2 business days. Thank you for reaching out to IPO Tank!
      </div>
      {/* <div style={{height:"300px"}}> </div> */}
    </div></div></>
  );
};

export default ContactUs;
