

import React, { useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import Modal from "react-modal"; // Import the Modal component
import "../Calendar.css"; // Your custom styles
import { Helmet } from 'react-helmet';

function RightsIssueCalendar({ allRightsIssues ,deviceInfo,navigation}) {
  // console.log(allRightsIssues)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setdata] = useState([]);

  // Memoize events
  const events = useMemo(() => {
    let events = [];
    allRightsIssues && allRightsIssues?.forEach(item => {
      events.push({
        date: new Date(item.record_date),
        title: item.company_name,
        type: "Record",
        color: "pink"
      });
      events.push({
        date: new Date(item.issue_open),
        title: item.company_name,
        type: "Open",
        color: "orange"
      });
      events.push({
        date: new Date(item.issue_close),
        title: item.company_name,
        type: "Close",
        color: "#e4e753"
      });
    });
    return events;
  }, [allRightsIssues]);

  // Set default data for selected date
  useEffect(() => {
    const currentDateEvents = events.filter(
      (event) => event.date.toDateString() === selectedDate.toDateString()
    );
    setdata(currentDateEvents);
  }, [selectedDate, events]);
 

console.log(events)
  
 
const [isOpen, setIsOpen] = useState(false);

  const onChange = (date) => {
    setSelectedDate(date);
  };

  const getEventsForDate = (date) => {
    return events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    );
  };


  const customTileContent = ({ date,view }) => {
    const eventsForDay = getEventsForDate(new Date(date));
  
    let eventDots;
    if (eventsForDay.length === 0) {
      // console.log(eventsForDay)
      eventDots = null;
    } else {
      const shownEvents = eventsForDay.slice(0,2); // Show only the first two events
      const hiddenEventsCount = eventsForDay.length - shownEvents.length;
      eventDots = (
        <div className="event-dots-container">
          {shownEvents.map((event, index) => (
            <div
              key={index}
              className="event-dot"
              style={{ backgroundColor: event.color }}
              title={event.title}
            ></div>
          ))}
          {hiddenEventsCount > 0 && (
            <div className="event-dot-plus">{`+${hiddenEventsCount}`}</div>
          )}
        </div>
      );
    }
  
    return (
      <div className="custom-tile">
        {eventDots}
      </div>
    );
  };
  
  

  const handleDateClick = (date) => {
    setSelectedDate(date);
   
     setdata(events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    ))
  
    setIsOpen(true);
  };
  console.log(data)

  return (
    <> 
  {/* <Helmet>
  <title>IPO Calendar - IPO schedule, important dates and updates at IPOtank</title>
  <meta name="description" content="Get the latest IPO schedule, updates and important dates at IPO tank. Explore a detailed IPO calendar featuring upcoming, current, closed, and recently listed IPOs. Access information on lot sizes, prices, subscriptions, and allotment statuses. Stay informed with IPOtank's comprehensive IPO schedule and updates." />
  <meta name="keywords" content="IPO, IPO schedule, IPO calendar, upcoming IPOs, current IPOs, closed IPOs, recently listed IPOs, IPO dates, IPO lot sizes, IPO prices, IPO subscriptions, IPO allotment, stock market, investments, IPOtank, IPO tank" />

  <meta property="og:url" content="https://www.ipotank.com/ipo-calendar" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="IPO Calendar - IPO schedule, important dates and updates at IPOtank" />
    <meta property="og:description" content="Get the latest IPO schedule, updates and important dates at IPO tank. Explore a detailed IPO calendar featuring upcoming, current, closed, and recently listed IPOs. Access information on lot sizes, prices, subscriptions, and allotment statuses. Stay informed with IPOtank's comprehensive IPO schedule and updates." />

</Helmet> */}


{/* <Helmet>
  <title>Buyback Calendar - Buyback schedule, important dates and updates at IPOtank</title>
  <meta name="description" content="Get the latest buyback schedule, updates, and important dates at IPOtank. Explore a detailed buyback calendar featuring upcoming, ongoing, and closed buybacks. Access information on buyback prices, record dates, issue sizes, and eligibility criteria. Stay informed with IPOtank's comprehensive buyback schedule and updates." />
  <meta name="keywords" content="Buyback, buyback schedule, buyback calendar, upcoming buybacks, ongoing buybacks, closed buybacks, buyback dates, buyback prices, record dates, issue sizes, IPOtank, IPO tank" />

  <meta property="og:url" content="https://www.ipotank.com/buyback-calendar" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Buyback Calendar - Buyback schedule, important dates and updates at IPOtank" />
  <meta property="og:description" content="Get the latest buyback schedule, updates, and important dates at IPOtank. Explore a detailed buyback calendar featuring upcoming, ongoing, and closed buybacks. Access information on buyback prices, record dates, issue sizes, and eligibility criteria. Stay informed with IPOtank's comprehensive buyback schedule and updates." />
</Helmet> */}


<Helmet>
  <title>Rights Issue Calendar - Schedule, Important Dates, and Updates at IPOtank</title>
  <meta name="description" content="Stay updated with the latest rights issue schedule, important dates, and updates at IPOtank. Explore detailed rights issue calendars featuring upcoming, ongoing, and closed rights issues. Access information on issue prices, record dates, issue sizes, and eligibility criteria. Stay informed with IPOtank's comprehensive rights issue schedule and updates." />
  <meta name="keywords" content="Rights issue, rights issue schedule, rights issue calendar, upcoming rights issues, ongoing rights issues, closed rights issues, rights issue dates, issue prices, record dates, issue sizes, IPOtank, IPO tank" />

  <meta property="og:url" content="https://www.ipotank.com/rights-issue-calendar" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Rights Issue Calendar - Schedule, Important Dates, and Updates at IPOtank" />
  <meta property="og:description" content="Stay updated with the latest rights issue schedule, important dates, and updates at IPOtank. Explore detailed rights issue calendars featuring upcoming, ongoing, and closed rights issues. Access information on issue prices, record dates, issue sizes, and eligibility criteria. Stay informed with IPOtank's comprehensive rights issue schedule and updates." />
</Helmet>


    <div>
  
      <div style={{ backgroundImage: "url('../best8.jfif')", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "10px 0 10px 0" }}>
      
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
          {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left', display: "flex", alignItems: "left", gap: "15px", color: "#666", paddingTop: "25px" }} >
              <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }} >
                <p>Home</p>
              </Link>
              <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
              <Link to={`/rights-issues`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
                <p>Rights Issues</p>
              </Link>
              <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
              <Link to={`/rights-issue-calendar`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
                <p>Calendar</p>
              </Link>
            </div>)}
            <br />
            {/* Title and Description */}
            <h1 style={{ marginTop: "-20px" }}>Rights Issues Calendar</h1>
            {/* <h3 style={{ marginTop: "30px" }}>The financial markets are on the move</h3> */}
            <p style={{ marginTop: "-10px", textAlign: "justify",fontSize:"15px"}}>Rights Issue calendar with details on upcoming, ongoing, and closed rights issues at IPOtank. Stay informed about important dates, issue prices, record dates, issue sizes, and eligibility criteria.</p>
          </div>
        </div>
      </div>
     
      {/* Calendar Section */}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ width: '90%', maxWidth: '1200px', margin: '40px 0' }}>
      <div className="event-calendar">
      <div className="calendar-container" >
        <Calendar
          onChange={onChange}
          value={selectedDate}
          tileContent={customTileContent}
          onClickDay={handleDateClick}
          className="react-calendar"
        />
   
    
        
      </div>
      <div
  isOpen={isOpen}
  onRequestClose={() => setIsOpen(false)}
  className="modal-content" // Apply modal styles
>
  {/* Modal content */}
  
  <h4 style={{color:"black",borderBottom:"1px solid rgb(221, 220, 221)",paddingBottom:"10px",textAlign:"left"}}>Rights Issues on {selectedDate.toDateString()}:</h4>
  <div style={{display:"flex",flexDirection:"row",alignContent:"center",gap:"10px",flexWrap:"wrap",marginTop:"-20px"}}>
  <div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
  <p className="dot"
  style={{ backgroundColor: "pink"}}></p>
  <p
              // className="dot"
              style={{ marginLeft:"5px",fontSize:"13px",color:"#666" }}
  >Record Day</p></div>
 <div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
  <p className="dot"
  style={{ backgroundColor: "orange"}}></p>
  <p
              // className="dot"
              style={{ marginLeft:"5px",fontSize:"13px",color:"#666" }}
  >Open Day</p></div>
  <div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
  <p className="dot"
  style={{ backgroundColor: "#e4e753"}}></p>
  <p
              // className="dot"
              style={{ marginLeft:"5px",fontSize:"13px",color:"#666" }}
  >Close Day</p></div></div>
  <div className="scrollable-container" style={{  height: "100%", width: "100%" }}>
<div style={{display:"flex",flexDirection:"column",gap:"10px",maxWidth:"500px",width:"100%"}}>
  {data && data.length > 0  ? (<>{data.map((event) => (
    <div key={event.title}>
     {/* <div> */}
     <Link to={`/rights-issue/${encodeURIComponent(event.title.toLowerCase().replace(/ /g, '-'))}`} style={{textDecoration:"none"}}>
        <div className="event-title" style={{fontSize:"14px",height:"auto",borderLeft:`10px solid ${event.color}`,borderTop:`2px solid ${event.color}`,borderRight:`2px solid ${event.color}`,borderBottom:`2px solid ${event.color}`,padding:"10px 10px",color:"#555",borderRadius:"8px", cursor: "pointer",  transition: "box-shadow 0.3s",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", textAlign:"left",// Initial box shadow
            }}
            onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"}
            onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>{event.title}<div style={{fontSize:"13px",color:"black",marginTop:"5px",fontWeight:"lighter"}}>{event.type}</div></div>
    </Link>
        {/* Add more event details here if needed */}
        </div>
    // </div>
  ))}</>):(<p style={{fontSize:"13px"}}>On this date, the record, opening and closing dates for the Rights Issues are not available.</p>)}
 
  </div></div>
  {/* <button className="modal-close-btn" onClick={() => setIsOpen(false)} style={{marginTop:"15px",background:"linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%)",color:"white" , cursor: "pointer",  transition: "box-shadow 0.3s",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
            }}
            onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"}
            onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>Close</button> */}
</div>
    </div>
    </div>
    </div>
    </div> </>
  );
}

export default RightsIssueCalendar;