// // // import React, { useEffect, useState } from 'react';

// // // const UserAgentDetector = () => {
// // //   const [deviceInfo, setDeviceInfo] = useState({
// // //     deviceType: '',
// // //     browser: '',
// // //     os: '',
// // //   });

// // //   useEffect(() => {
// // //     const userAgent = navigator.userAgent;

// // //     let deviceType = 'Desktop';  // Default to desktop
// // //     let browser = 'Unknown';
// // //     let os = 'Unknown';

// // //     // Detect Device Type
// // //     if (/Mobi/i.test(userAgent) || /Android/i.test(userAgent)) {
// // //       deviceType = 'Mobile';
// // //     } else if (/Tablet/i.test(userAgent) || /iPad/i.test(userAgent)) {
// // //       deviceType = 'Tablet';
// // //     }

// // //     // Detect Browser
// // //     if (userAgent.indexOf('Firefox') > -1) {
// // //       browser = 'Mozilla Firefox';
// // //     } else if (userAgent.indexOf('SamsungBrowser') > -1) {
// // //       browser = 'Samsung Internet';
// // //     } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
// // //       browser = 'Opera';
// // //     } else if (userAgent.indexOf('Trident') > -1) {
// // //       browser = 'Microsoft Internet Explorer';
// // //     } else if (userAgent.indexOf('Edge') > -1) {
// // //       browser = 'Microsoft Edge';
// // //     } else if (userAgent.indexOf('Chrome') > -1) {
// // //       browser = 'Google Chrome';
// // //     } else if (userAgent.indexOf('Safari') > -1) {
// // //       browser = 'Safari';
// // //     }

// // //     // Detect Operating System
// // //     if (/Windows NT/i.test(userAgent)) {
// // //       os = 'Windows';
// // //     } else if (/Mac OS X/i.test(userAgent)) {
// // //       os = 'Mac OS';
// // //     } else if (/Android/i.test(userAgent)) {
// // //       os = 'Android';
// // //     } else if (/iOS/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
// // //       os = 'iOS';
// // //     } else if (/Linux/i.test(userAgent)) {
// // //       os = 'Linux';
// // //     }

// // //     // Set the gathered information in the state
// // //     setDeviceInfo({
// // //       deviceType,
// // //       browser,
// // //       os,
// // //     });
// // //   }, []);

// // //   return (
// // //     <div>
// // //       <h2>Device Information</h2>
// // //       <p><strong>Device Type:</strong> {deviceInfo.deviceType}</p>
// // //       <p><strong>Browser:</strong> {deviceInfo.browser}</p>
// // //       <p><strong>Operating System:</strong> {deviceInfo.os}</p>
// // //     </div>
// // //   );
// // // };

// // // export default UserAgentDetector;

// import React, { useEffect, useState } from 'react';
// import Bowser from 'bowser';  // Import Bowser library

// const UserAgentDetector = () => {
//   const [deviceInfo, setDeviceInfo] = useState({
//     deviceType: '',
//     browser: '',
//     browserVersion: '',
//     os: '',
//     osVersion: '',
//     platform: '',
//     isTouchDevice: false,
//   });
//   console.log("deviceInfo...",deviceInfo);

//   useEffect(() => {
//     // Use Bowser's parse() function to get user agent details
//     const browserInfo = Bowser.parse(window.navigator.userAgent);

//     // Extract useful data
//     const browserName = browserInfo.browser.name || 'Unknown';
//     const browserVersion = browserInfo.browser.version || 'Unknown';
//     const osName = browserInfo.os.name || 'Unknown';
//     const osVersion = browserInfo.os.version || 'Unknown';
//     const platformType = browserInfo.platform.type || 'Unknown';  // mobile, tablet, desktop
//     const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;


//     // Determine if it's an iPad explicitly
//     const isIpad = navigator.platform === 'iPad' || 
//                    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

//     // Determine device type based on platform
//     let deviceType = 'Desktop';
//     if (isIpad) {
//       deviceType = 'iPad';
//     } else if (platformType === 'mobile') {
//       deviceType = 'Mobile';
//     } else if (platformType === 'tablet') {
//       deviceType = 'Tablet';
//     }

//     // // Determine device type based on platform
//     // let deviceType = 'Desktop';
//     // if (platformType === 'mobile') {
//     //   deviceType = 'Mobile';
//     // } else if (platformType === 'tablet') {
//     //   deviceType = 'Tablet';
//     // }

//     // Set the gathered information in the state
//     setDeviceInfo({
//       deviceType,
//       browser: browserName,
//       browserVersion,
//       os: osName,
//       osVersion,
//       platform: platformType,
//       isTouchDevice,
//     });
//   }, []);

//   return (
//     <>
//     </>
//     // <div>
//     //   <h2>Device Information</h2>
//     //   <p><strong>Device Type:</strong> {deviceInfo.deviceType}</p>
//     //   <p><strong>Browser:</strong> {deviceInfo.browser} (Version: {deviceInfo.browserVersion})</p>
//     //   <p><strong>Operating System:</strong> {deviceInfo.os} (Version: {deviceInfo.osVersion})</p>
//     //   <p><strong>Platform:</strong> {deviceInfo.platform}</p>
//     //   <p><strong>Touch Device:</strong> {deviceInfo.isTouchDevice ? 'Yes' : 'No'}</p>
//     // </div>
//   );
// };

// export default UserAgentDetector;



import React, { useEffect, useState } from 'react';
import Bowser from 'bowser';  // Import Bowser library
import {hostInfo} from '../server'

const checkDevice = (info) => {
  // Check if OS name is Android and version is 99
  if (info.os.name === "Android" && info.os.version === "99") {
      // Check platform type and if vendor is present
      if (info.platform.type === "mobile" && info.platform.vendor) {
          return "app"; // Return 'app' if all conditions are met
      }
  }
  return null; // Return null if conditions are not met
};

const UserAgentDetector = () => {
  const [deviceInfo, setDeviceInfo] = useState("");
  console.log(deviceInfo);

  useEffect(() => {
    // Use Bowser's parse() function to get user agent details
    const browserInfo = Bowser.parse(window.navigator.userAgent);
    console.log(browserInfo);
    // // Extract useful data
    // const browserName = browserInfo.browser.name || 'Unknown';
    // const browserVersion = browserInfo.browser.version || 'Unknown';
    // const osName = browserInfo.os.name || 'Unknown';
    // const osVersion = browserInfo.os.version || 'Unknown';
    // const platformType = browserInfo.platform.type || 'Unknown';  // mobile, tablet, desktop
    // const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    // // Determine if it's an iPad explicitly
    // const isIpad = navigator.platform === 'iPad' || 
    //                (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    // // Determine device type based on platform
    // let deviceType = 'Desktop';
    // if (isIpad) {
    //   deviceType = 'iPad';
    // } else if (platformType === 'mobile') {
    //   deviceType = 'Mobile';
    // } else if (platformType === 'tablet') {
    //   deviceType = 'Tablet';
    // } else {
    //   deviceType = platformType;
    // }

    // // Set the gathered information in the state
    // const deviceData = {
    //   deviceType,
    //   browser: browserName,
    //   browserVersion,
    //   os: osName,
    //   osVersion,
    //   platform: platformType,
    //   isTouchDevice,
    // };

   
  
  // Call the function
  const result = checkDevice(browserInfo);
  console.log(result);

    setDeviceInfo(result);

    // Send the device data to the backend
    fetch(`${hostInfo}/logDeviceInfo`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(browserInfo),
    });
  }, []);

  return deviceInfo; // No UI needed
};

export default UserAgentDetector;
