import React from 'react';
import ShareButton from './ShareButton';

const IPOStatusComponent = ({ openDate, closeDate, allotmentDate, isAllotmentOut,ipoName, listingDate }) => {
  // console.log(listingDate,closeDate);

  const stripTime = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const currentDate = new Date();

   // Check if closedDate is valid
   if (isNaN(closeDate)) {
    console.error("Invalid Closed Date:", closeDate);
  }


  let displayElement = null;

  // console.log("Current Date1:", currentDate);
  // console.log("Closed Date1:", closeDate);
  // console.log("Allotment Date1:", allotmentDate);
  // console.log("Is Allotment Out1:", isAllotmentOut);

  
  if (openDate < closeDate && currentDate >= allotmentDate && isAllotmentOut) {
    displayElement = (
      <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#dd8f00", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#fdf5e6", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#dd8f00", margin: "1px 5px 0px 0px" }}></div>
          Allotment Out
        </label>
      </div>
    );
  }else if (openDate < closeDate && currentDate >= allotmentDate && !isAllotmentOut && currentDate >= listingDate) {
    displayElement = (
      <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#dd8f00", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#fdf5e6", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#dd8f00", margin: "1px 5px 0px 0px" }}></div>
          Allotment Out
        </label>
      </div>
    );
  }else if (openDate < closeDate && allotmentDate && currentDate >= allotmentDate && !isAllotmentOut) {
    displayElement = (
      <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Allotment Awaited
        </label>
      </div>
    );
  } else {
    displayElement = null;
  }


  return (
    <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
    
    {openDate < closeDate && allotmentDate !== null && (<>  
    {openDate && closeDate && (() => {
  const currentDate = new Date();


  return (
    currentDate.toDateString() === openDate.toDateString() ||
    currentDate.toDateString() === closeDate.toDateString() ||
    (currentDate >= openDate && currentDate <= closeDate)
  );
})() && (
  <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
    <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
     
      <img src="/Dott.gif" style={{ width: "10px", height: "10px", margin: "1px 3px 0px 0px"}}/>
      Live
    </label>
  </div>
)}

{openDate < closeDate  && allotmentDate && (() => {
  const CurrentDate = stripTime(new Date());
  const ClosedDate = stripTime(closeDate);
  const AllotmentDate = stripTime(allotmentDate);
 
  return (
    ClosedDate < CurrentDate && CurrentDate < AllotmentDate
  );
})() ? (
  <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Allotment Awaited
        </label>
      </div>
):(<>{displayElement}</>)}</>)}

                      
  {/* {openDate && closeDate && (() => {
  const currentDate = new Date();


  return (
    currentDate.toDateString() === openDate.toDateString() ||
    currentDate.toDateString() === closeDate.toDateString() ||
    (currentDate >= openDate && currentDate <= closeDate)
  );
})() && (
  <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
    <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
     
      <img src="/Dott.gif" style={{ width: "10px", height: "10px", margin: "1px 3px 0px 0px"}}/>
      Live
    </label>
  </div>
)}

{closeDate  && (() => {
  const CurrentDate = stripTime(new Date());
  const ClosedDate = stripTime(closeDate);
  const AllotmentDate = stripTime(allotmentDate);
 
  return (
    ClosedDate < CurrentDate && CurrentDate < AllotmentDate
  );
})() ? (
  <div style={{ fontSize: "14px", margin: "5px 0", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Allotment Awaited
        </label>
      </div>
):(<div>{displayElement}</div>)} */}
   
    {/* <div style={{marginLeft:"10px"}}> */}
    {/* <div> */}
    <ShareButton ipoLink={`https://ipotank.com/ipo/${ipoName}`} companyname={ipoName} />
    {/* </div> */}
    </div>
  );
};

export default IPOStatusComponent;
