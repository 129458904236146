import React, { useEffect, useState } from 'react';
import '../BuybackDetails.css'; // Custom styling
import { Card, CardContent, Typography,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, useMediaQuery, Box, Divider } from '@mui/material';
import {  useTheme } from '@material-ui/core';
import { json, Link, useNavigate, useParams } from 'react-router-dom';
import { hostInfo, token  } from '../server';
import { Helmet } from 'react-helmet';
import { SlArrowRight } from "react-icons/sl";
import { MdSubscriptions } from "react-icons/md";

const RightsIssueDetails = ({deviceInfo,navigation}) => {
  const [rightsIssueDetails, setRightsIssueDetails] = useState([]);
  console.log(rightsIssueDetails,"..................")

  const Params = useParams();
  
  const nevigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${hostInfo}/get_rights_issue_details`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
            'Content-Type': 'application/json', // Set the Content-Type if necessary
          },
          body: JSON.stringify({ company_route_name: Params["rights-issue-details"] })
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        console.log(data)
        setRightsIssueDetails(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        nevigate('/');
        // Handle the error as needed
      }
    };
  
    fetchData();

  }, [Params]);

  // const {
  //   about_section,
  //   additional_text,
  //   buyback_details,
  //   buyback_ratio,
  //   calculation_section,
  //   company_contact,
  //   financials,
  //   image_url,
  //   ipo_name,
  //   issue_timetable,
  //   objective,
  //   participating_section,
  //   registrar_contact,
  // } = data;

  const rights_issue_details_obj =rightsIssueDetails &&  rightsIssueDetails?.rights_issue_details && JSON.parse(rightsIssueDetails?.rights_issue_details);
  console.log(rights_issue_details_obj);
  // Access the Rights Issue Details
  const rights_issue_details_Obj = rights_issue_details_obj && JSON.parse(rights_issue_details_obj);
  console.log(rights_issue_details_Obj);

  // const buybackRatioObj = rightsIssueDetails &&  rightsIssueDetails?.buyback_ratio && JSON.parse(rightsIssueDetails?.buyback_ratio);
  // const calculationSectionObj = rightsIssueDetails &&  rightsIssueDetails?.calculation_section && JSON.parse(rightsIssueDetails?.calculation_section);
  // const investmentCalc = calculationSectionObj && calculationSectionObj["Calculation of Investment"];
  // console.log(calculationSectionObj);
  // const expectedProfit = calculationSectionObj && calculationSectionObj["Expected Profit from Buyback"];
  // console.log(expectedProfit);

  const issueTimetableobj = rightsIssueDetails &&  rightsIssueDetails?.issue_timetable && JSON.parse(rightsIssueDetails?.issue_timetable);
  console.log(issueTimetableobj);
  // Access the Rights Issue Details
  const issueTimetableObj = issueTimetableobj && JSON.parse(issueTimetableobj);
  console.log(issueTimetableObj);

  const Objectiveobj = rightsIssueDetails &&  rightsIssueDetails?.objectives && JSON.parse(rightsIssueDetails?.objectives);

  const companyContactobj = rightsIssueDetails &&  rightsIssueDetails?.company_contact && JSON.parse(rightsIssueDetails?.company_contact);
  const companyContactObj = companyContactobj && JSON.parse(companyContactobj);
  console.log(companyContactObj);
  const registrarContactobj = rightsIssueDetails &&  rightsIssueDetails?.registrar_contact && JSON.parse(rightsIssueDetails?.registrar_contact);
  const registrarContactObj = registrarContactobj && JSON.parse(registrarContactobj);
  console.log(registrarContactObj);

  const company_informationObj = rightsIssueDetails && rightsIssueDetails?.company_information && JSON.parse(rightsIssueDetails?.company_information);
  // const company_informationObj = company_informationobj && JSON.parse(company_informationobj);
  console.log(company_informationObj);



  const financialsobj = rightsIssueDetails &&  rightsIssueDetails?.financial_details && JSON.parse(rightsIssueDetails?.financial_details);
  const financialsObj = financialsobj && JSON.parse(financialsobj);
  const financialData = financialsObj && financialsObj["FinancialData"]
  const footnote =  financialsObj && financialsObj["Footnote"]
  // console.log(financialsObj[0]);
  // const financialsObjheaders = financialsObj && financialsObj[0]["Period Ended"];
  // console.log(financialsObjheaders);
  // // Extract column headers dynamically
  // const headers =financialsObjheaders &&  ["Period Ended", ...Object.keys(financialsObjheaders).filter(key => key !== "Period Ended")];

  const subscription_statusobj = rightsIssueDetails &&  rightsIssueDetails?.subscription_status && JSON.parse(rightsIssueDetails?.subscription_status);
  const paragraphObj = subscription_statusobj && subscription_statusobj["paragraph"]
  const table_headersObj = subscription_statusobj && subscription_statusobj["table_headers"]
  const table_dataObj =  subscription_statusobj && subscription_statusobj["table_data"]
console.log(paragraphObj,table_headersObj,table_dataObj);
// Extracting the last value from the last row
const lastRow = table_dataObj && table_dataObj[table_dataObj.length - 1]; // Get the last row
const lastValue = lastRow && lastRow[lastRow.length - 1]; // Get the last value of that row

   // Extract the headers and rows from the financial data
   const headers = financialData && financialData.map((item) => Object.keys(item)[0]);
   const numberOfRows = financialData && financialData[0][headers[0]].length; // Assumes all arrays have the same length
 
  console.log(headers);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  const stripTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };
  
  // Get the current date without time
  const currentDate = stripTime(new Date());

  

  return (
<>
    {/* <Helmet>
    <title>{`${rightsIssueDetails?.company_name} - Comprehensive Trading and Investment Services`}</title>
    <meta name="description" content={`${rightsIssueDetails?.company_name}, a leading ${rightsIssueDetails?.additional_text} in India, offers a wide range of trading and investment services including equity, commodities, futures, and options. With a robust platform and competitive brokerage plans, ${rightsIssueDetails?.company_name} is a trusted choice for investors.`} />
    <meta name="keywords" content={`${rightsIssueDetails?.company_name}, ${rightsIssueDetails?.additional_text}, equity trading, commodity trading, futures trading, options trading, stock market, investment services, IPOtank, IPO tank`} />
 
<meta property="og:url" content={`https://www.ipotank.com/broker/${Params["rights-issue-details"]}`} />
<meta property="og:type" content="website" />
<meta property="og:title" content={`${rightsIssueDetails?.company_name} - Comprehensive Trading and Investment Services`} />
<meta property="og:description" content={`${rightsIssueDetails?.company_name}, a leading ${rightsIssueDetails?.additional_text} in India, offers a wide range of trading and investment services including equity, commodities, futures, and options. With a robust platform and competitive brokerage plans, ${rightsIssueDetails?.company_name} is a trusted choice for investors.`} />
  </Helmet> */}

  {/* <Helmet>
    
      <title>{`${rightsIssueDetails?.company_name} - Key Information and Investment Details`}</title>

     
      <meta 
        name="description" 
        content={`${rightsIssueDetails?.company_name} is conducting a ${rightsIssueDetails?.additional_text} buyback. Explore details including buyback price, company performance, and future prospects.`} 
      />

    
      <meta 
        name="keywords" 
        content={`${rightsIssueDetails?.company_name}, ${rightsIssueDetails?.additional_text} buyback, buyback offer, investment opportunities, share repurchase, IPOtank, IPO tank`} 
      />

    
      <meta property="og:url" content={`https://www.ipotank.com/rights-issue/${Params["rights-issue-details"]}`} />
      <meta property="og:type" content="website" />
      <meta 
        property="og:title" 
        content={`${rightsIssueDetails?.company_name} - Key Information and Investment Details`} 
      />
      <meta 
        property="og:description" 
        content={`${rightsIssueDetails?.company_name} is conducting a ${rightsIssueDetails?.additional_text} buyback. Explore details including buyback price, company performance, and future prospects.`} 
      />
    </Helmet> */}

<Helmet>
  {/* Dynamic Title */}
  <title>{`${rightsIssueDetails?.company_name} - Key Information and Investment Details`}</title>

  {/* Meta Description */}
  <meta 
    name="description" 
    content={`${rightsIssueDetails?.company_name} is conducting a rights issue. Explore details including issue price, company performance, and investment prospects.`} 
  />

  {/* Meta Keywords */}
  <meta 
    name="keywords" 
    content={`${rightsIssueDetails?.company_name}, rights issue, investment opportunities, share offering, capital raise, IPOtank, IPO tank`} 
  />

  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:url" content={`https://www.ipotank.com/rights-issue/${rightsIssueDetails?.company_route_name}`} />
  <meta property="og:type" content="website" />
  <meta 
    property="og:title" 
    content={`${rightsIssueDetails?.company_name} - Key Information and Investment Details`} 
  />
  <meta 
    property="og:description" 
    content={`${rightsIssueDetails?.company_name} is conducting a rights issue. Explore details including issue price, company performance, and investment prospects.`} 
  />
</Helmet>



  <div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }}
> 
<div style={{ width: '90%', maxWidth: '1200px' }}>
{(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"10px"}} >
  <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
  onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
    <p>
      Home
    </p>
  </Link>
  <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>

  <Link to={`/rights-issues`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
  onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
    <p>Rights Issues</p>
  </Link>
  <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
  <Link to={`/rights-issue/${Params["rights-issue-details"]}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
  onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
    <p>{rightsIssueDetails?.company_name?.split(' ').length > 2 
    ? rightsIssueDetails?.company_name?.split(' ').slice(0, 2).join(' ') + '...' 
    : rightsIssueDetails?.company_name}</p>
  </Link>
</div>)}

</div>
</div>



  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"5px",
      marginBottom:"30px",
      textAlign:"justify",
    }}
  >
       
  <div  style={{width: "90%",maxWidth:"1200px",minWidth:"100px",height:"auto" }}>


  {/* <div style={{ margin:"0px 0px 0px 0px",borderRadius: "10px",
boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
backgroundColor: "#f9f9f9",padding:"10px"}}> */}

 {/* <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"20px",alignItems:"center"}}><img src={brokerData?.Broker_Logo} alt="" style={{width:"100px",maxHeight:"100px"}}/><div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}><div><h2 style={{margin:"0px"}}>{brokerData?.Broker_Name}</h2><div>{brokerData?.Broker_Type}</div></div><a href={brokerData?.Open_Demat_Account_Url} target='blank' style={{textDecoration:"none"}}>Open Demat Account</a></div></div> */}

 <div className="header">
<img 
  src={rightsIssueDetails?.image_url} 
  alt={`${rightsIssueDetails?.company_name} Logo`} 
  // className="company-logo"
   style={{
                    height: '90px',
                    objectFit: 'contain',
                    width: '90px',
   }}
   loading="lazy"
/>
<div 
  className={`broker-card-content ${window.innerWidth <= 768 ? 'broker-card-content-column' : ''}`}
  style={{gap:"5px"}}
>
  <div style={{ flex: "1" }}> 
    <h3 style={{marginTop:"0",marginBottom:"0"}}>
    {rightsIssueDetails?.company_name}
    </h3>
    {rights_issue_details_Obj && rights_issue_details_Obj["Rights Issue Details"]?.Entitlement && (<div className="broker-type" style={{fontSize:"13px",marginTop:"2px"}}>
      {rights_issue_details_Obj && rights_issue_details_Obj["Rights Issue Details"].Entitlement}
    </div>)}
    <div style={{display:"flex",flexDirection:"row",gap:"5px",flexWrap:"wrap",alignItems:"center",marginTop:"5px"}}>
    {(currentDate >= stripTime(new Date(rightsIssueDetails?.issue_open)) && currentDate <= stripTime(new Date(rightsIssueDetails?.issue_close))) && (  <div style={{ fontSize: "14px", margin: "0px 5px 0px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
    <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
     
      <img src="/Dott.gif" style={{ width: "10px", height: "10px", margin: "1px 3px 0px 0px"}}/>
      Live
    </label>
  </div>)}
  
  {(currentDate < stripTime(new Date(rightsIssueDetails?.issue_open)) || rightsIssueDetails?.issue_open === null) && (<div style={{ fontSize: "14px", margin: "0px 5px 0px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Upcoming
        </label>
      </div>)}
    {(currentDate > stripTime(new Date(rightsIssueDetails?.issue_close)) && rightsIssueDetails?.issue_open !== null) && (<div style={{ fontSize: "14px", margin: "0px 5px 0px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#dd8f00", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#fdf5e6", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#dd8f00", margin: "1px 5px 0px 0px" }}></div>
          Closed
        </label>
      </div>)}  
      {lastValue && (<div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='#888'/><div style={{color:"#888"}}>Subs:</div><div style={{color:"rgb(107, 128, 179)"}}>{lastValue}</div></div>)}</div>

    {/* <div className="broker-type" style={{fontSize:"13px",marginTop:"0"}}>
      {rightsIssueDetails?.additional_text}
    </div> */}
   
  </div>
  {/* <div style={{display:"flex", flexWrap:"wrap", alignItems:"center",gap:"3px"}}>
    <div><strong style={{fontSize:"16px",color:"#00796b"}}>{rightsIssueDetails?.buyback_price}</strong></div><div style={{fontSize:"16px",color:"#666"}}>/</div><div style={{fontSize:"13px",color:"#666"}}>Buyback price per share</div>
  </div> */}
</div>
</div>

{/* </div>  */}


   
 {/* <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 0px 0px",}}>About Company</h3> */}
 {/* <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: rightsIssueDetails?.rights_issues_information }} /></div> */}



     <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>{rightsIssueDetails?.company_name} Rights Issue Details</h3>
     <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: rightsIssueDetails?.rights_issues_information }} /></div>
     <TableContainer component={Paper} sx={{ maxWidth: '700px', overflowX: 'auto', marginTop: 2 }}>
      {/* <Typography variant="h5" align="center" gutterBottom>
        Issue Timetable
      </Typography> */}
      <Table aria-label="issue timetable">
      {/* <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={2} sx={{  backgroundColor: '#00796b', color: 'white', fontSize: "16px" }}>
          {rightsIssueDetails?.company_name} Details
          </TableCell>
        </TableRow>
      </TableHead> */}
      <TableBody>
        {rights_issue_details_Obj && rights_issue_details_Obj["Rights Issue Details"] && Object.entries(rights_issue_details_Obj["Rights Issue Details"]).map(([key, value], index) => (
          <TableRow key={index}>
            <TableCell sx={{ fontWeight: 'bold', fontSize: "14px", backgroundColor: '#e0f2f1', width: "50%" }}>
              {key}
            </TableCell>
            <TableCell sx={{ fontSize: "14px", backgroundColor: '#ffffff', width: "50%" }}>
              {value === "" ? "- - - -" : value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      {/* <Table aria-label="issue timetable">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#00796b', color: 'white', fontSize:"15px" }}>
              Event
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#00796b', color: 'white', fontSize:"15px"}}>
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issueTimetableObj && Object.entries(issueTimetableObj).map(([event, date], index) => (
            <TableRow key={index}>
              <TableCell align="left">{event}</TableCell>
              <TableCell align="left">{date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </TableContainer>
     {/* <div className="brokerage-plans">
      <div className="plan-content" style={{paddingBottom:"0px"}}>
      <div className="plan-section-content">

   
      {rights_issue_details_Obj && rights_issue_details_Obj["Rights Issue Details"] && Object.entries(rights_issue_details_Obj["Rights Issue Details"]).map(([key, value]) => (
        <div 
          className="plan-item" 
          style={{boxShadow:"0 4px 8px rgba(0, 0, 0, 0.2)"}}
          key={key}
        >
          <span className="plan-item-key" style={{color:"#666"}}>
            {key}:
          </span>
          <span className="plan-item-value">
            <span style={{ color: '#00796b'}}>
              {value ? value.replace('\\u20b9', '₹') : "TBA"}
            </span>
          </span>
        </div>
      ))}
 
     
       </div>
      </div>
    </div> */}

    {/* <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  > 
   <div style={{ width: '93%', maxWidth: '1200px' }}>
    <div className="buyback-container"> */}
      {/* Header Section */}
      {/* <div className="header">
        <img src={rightsIssueDetails?.image_url} alt={rightsIssueDetails?.company_name} className="company-logo" />
        <Typography variant="h4" className="company-title">{rightsIssueDetails?.company_name}</Typography>
      </div> */}

      {/* About Section */}
      {/* <Card className="about-section">
        <CardContent>
          <Typography variant="h6">About the Company</Typography>
          <div dangerouslySetInnerHTML={{ __html: rightsIssueDetails?.about_section }} />
        </CardContent>
      </Card> */}

      {/* Buyback Details */}
      {/* <Card className="buyback-details-section">
        <CardContent>
          <Typography variant="h6">Buyback Details</Typography>
          <Typography><b>Security Name:</b> {rights_issue_details_Obj && rights_issue_details_Obj["Security Name"]}</Typography>
          <Typography><b>Buyback Price:</b> {rights_issue_details_Obj && rights_issue_details_Obj["Buyback Price"]}</Typography>
          <Typography><b>Lead Manager:</b> {rights_issue_details_Obj && rights_issue_details_Obj["Lead Manager"]}</Typography>
          <Typography><b>Issue Size:</b> {rights_issue_details_Obj && rights_issue_details_Obj["Issue Size"]}</Typography>
          <a href={rights_issue_details_Obj && rights_issue_details_Obj["Buyback Docs"]} target="_blank" rel="noopener noreferrer">View Buyback Documents</a>
        </CardContent>
      </Card> */}

<h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 5px 0px"}}>Issue Timetable</h3>
      {/* <Typography variant="h6" style={{marginTop:"20px"}}>Issue Timetable</Typography> */}
      <TableContainer component={Paper} sx={{ maxWidth: '500px', overflowX: 'auto', marginTop: 2 }}>
      {/* <Typography variant="h5" align="center" gutterBottom>
        Issue Timetable
      </Typography> */}
      <Table aria-label="issue timetable">
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={2} sx={{  backgroundColor: '#00796b', color: 'white', fontSize: "16px" }}>
          {rightsIssueDetails?.company_name}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {issueTimetableObj && issueTimetableObj["Issue Timetable"] && Object.entries(issueTimetableObj["Issue Timetable"]).map(([event, date], index) => (
          <TableRow key={index}>
            <TableCell sx={{ fontWeight: 'bold', fontSize: "14px", backgroundColor: '#e0f2f1', width: "50%" }}>
              {event}
            </TableCell>
            <TableCell sx={{ fontSize: "14px", backgroundColor: '#ffffff', width: "50%" }}>
              {date === "" ? "- - - -" : date}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      {/* <Table aria-label="issue timetable">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#00796b', color: 'white', fontSize:"15px" }}>
              Event
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#00796b', color: 'white', fontSize:"15px"}}>
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issueTimetableObj && Object.entries(issueTimetableObj).map(([event, date], index) => (
            <TableRow key={index}>
              <TableCell align="left">{event}</TableCell>
              <TableCell align="left">{date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </TableContainer>

    {/* <Typography variant="h6" style={{marginTop:"20px"}}>Issue Timetable</Typography> */}
    {/* <Paper sx={{ padding: 3, maxWidth: '100%', marginTop: 2 }}> */}
      {/* <Typography variant="h5" align="center" gutterBottom>
        Issue Timetable
      </Typography> */}

      {/* <Grid container spacing={2}>
        {issueTimetableObj && Object.entries(issueTimetableObj).map(([event, date], index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card sx={{ minWidth: 275, margin: 1 }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {event}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
    {/* </Paper> */}

      {/* Buyback Ratio */}
      {/* <Card className="buyback-ratio-section">
        <CardContent> */}
      
        {/* {buybackRatioObj && (<>
        <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 0px 0px"}}>Buyback Ratio</h3>
       
          <Table sx={{ minWidth: 200 }}>
            <TableHead style={{fontWeight:"bold"}} >
              <TableRow>
                <TableCell style={{fontWeight:"bold",fontSize:"15px",color:"#00796b"}}>Category</TableCell>
                <TableCell style={{fontWeight:"bold",fontSize:"15px",color:"#00796b"}}>Entitlement Ratio</TableCell>
                <TableCell style={{fontWeight:"bold",fontSize:"15px",color:"#00796b"}}>Shares Offered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buybackRatioObj?.map((ratio, index) => (
                <TableRow key={index}>
                  <TableCell style={{color:"#666"}}>{ratio.Category}</TableCell>
                  <TableCell>{ratio["Entitlement Ratio of Buyback"]}</TableCell>
                  <TableCell>{ratio["Shares Offered"]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{padding:"10px 0px 0px 0px",fontSize:"13px",color:"#666"}}><i>* Entitlement Ratio (x/y) = x Equity Shares out of every y Fully paid-up Equity Shares held on the Record Date.	</i></div>
          </>)} */}
          
        {/* </CardContent>
      </Card> */}

      {/* Financials Section */}
      {/* <Card className="financials-section"> */}
        {/* <CardContent> */}
        {financialData && (<> 
        {/* <Typography variant="h6" style={{marginTop:"20px"}}>Company Financials</Typography> */}
        <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 5px 0px"}}>Company Financial Information</h3>
      
    <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', marginTop: 2 }}>
        <Table aria-label="financials table">
          <TableHead>
            <TableRow>
              {/* Add the first empty header for the column period */}
              {/* <TableCell sx={{ fontWeight: "bold", backgroundColor: '#00796b', color: 'white' }} /> */}
              <TableCell
                  // key={index}
                  align="left"
                  sx={{ fontWeight: "bold", backgroundColor: '#00796b', color: 'white' }}
                >Period Ended
              </TableCell>
              {financialData && financialData[0]['Period Ended'].map((period, index) => (
                <TableCell
                  key={index}
                  align="left"
                  sx={{ fontWeight: "bold", backgroundColor: '#00796b', color: 'white' }}
                >
                  {period}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {financialData && financialData.slice(1).map((row, rowIndex) => {
              const rowKey = Object.keys(row)[0];
              return (
                <TableRow key={rowKey}>
                  <TableCell sx={{ fontWeight: "bold", color: '#00796b' }}>{rowKey}</TableCell>
                  {row[rowKey].map((value, colIndex) => (
                    <TableCell key={colIndex} align="left" sx={{ color: 'black' }}>
                      {value === "" ? "-" : value}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          <caption style={{ textAlign: "right", fontStyle: "italic", padding: "10px" }}>
            {footnote && footnote.replace("â¹","₹")}
          </caption>
        </Table>
      </TableContainer></>)}



 {subscription_statusobj && paragraphObj && table_headersObj && table_dataObj && (<> 
        {/* <Typography variant="h6" style={{marginTop:"20px"}}>Company Financials</Typography> */}
        <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 5px 0px"}}>Subscription Details (Day Wise)</h3>
        <div style={{marginTop:"10px",color:"#666",textAlign:"left"}}>{paragraphObj}</div>

    {table_dataObj && table_dataObj.length > 0 && (<TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', marginTop: 2 }}>
        <Table aria-label="financials table">
          <TableHead>
            <TableRow>
              {/* Add the first empty header for the column period */}
              {/* <TableCell sx={{ fontWeight: "bold", backgroundColor: '#00796b', color: 'white' }} /> */}
              {/* <TableCell
                  // key={index}
                  align="left"
                  sx={{ fontWeight: "bold", backgroundColor: '#00796b', color: 'white' }}
                >Period Ended
              </TableCell> */}
              {table_headersObj && table_headersObj.map((period, index) => (
                <TableCell
                  key={index}
                  align="left"
                  sx={{ fontWeight: "bold", backgroundColor: '#e0f2f1', color: '#00796b' }}
                >
                  {period}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {table_dataObj && table_dataObj.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {/* <TableCell sx={{ fontWeight: "bold", color: '#00796b' }}>{rowKey}</TableCell> */}
                  {row.map((value, colIndex) => (
                    <TableCell key={colIndex} align="left" sx={{ color: 'black' }}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
            
            ))}
          </TableBody>
          {/* <caption style={{ textAlign: "right", fontStyle: "italic", padding: "10px" }}>
            {footnote && footnote.replace("â¹","₹")}
          </caption> */}
        </Table>
      </TableContainer>)}</>)}


      {Objectiveobj && (<><h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>{rightsIssueDetails?.company_name} Objectives</h3>
      <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: Objectiveobj }} /></div></>)}



      {company_informationObj && company_informationObj["About Company"] && (<><h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>About Company</h3>
      <div style={{marginTop:"10px",color:"#666",textAlign:"left"}}>{company_informationObj["About Company"]}</div></>)}

      {company_informationObj && company_informationObj["Financial Performance"] && (<><h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>Financial Performance</h3>
      <div style={{marginTop:"10px",color:"#666",textAlign:"left"}}>{company_informationObj["Financial Performance"]}</div></>)}

      {company_informationObj && company_informationObj["Dividend Policy"] && (<><h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>Dividend Policy</h3>
      <div style={{marginTop:"10px",color:"#666",textAlign:"left"}}>{company_informationObj["Dividend Policy"]}</div></>)}


         
 {/* <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 0px 0px",}}>About Company</h3> */}
 {/* <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: rightsIssueDetails?.rights_issues_information }} /></div> */}
    
         
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableCell>Period Ended</TableCell>
                {Object.keys(financials[0]).filter((key) => key !== 'Period Ended').map((year, index) => (
                  <TableCell key={index}>{year}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {financials.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row["Period Ended"]}</TableCell>
                  <TableCell>{row["31-Mar-23"]}</TableCell>
                  <TableCell>{row["31-Mar-22"]}</TableCell>
                  <TableCell>{row["31-Mar-21"]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
        {/* </CardContent> */}
      {/* </Card> */}

     
  

      {/* Participation Section */}
      {/* {participatingSectionObj && ( <>
       
          <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"30px 0px 0px 0px"}}>{participatingSectionObj && participatingSectionObj.heading}</h3>
          <ul style={{listStyle:"none",paddingLeft:"0px"}}>
            {participatingSectionObj && participatingSectionObj.steps.map((step, index) => (
              <li key={index} style={{padding:"5px 0px"}}>{step}</li>
            ))}
          </ul>
         
      
       </>
) } */}


{/* <Box sx={{ padding: 4 }}> */}
     

      {/* Calculation of Investment Section */}
    
    {/* <Card
  sx={{
    mb: 4,
    backgroundColor: '#f9f9f9',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: 2,
  }}
>
  <CardContent> */}
    {/* <Typography
      variant="h5"
      gutterBottom
      sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        mb: 3,
        color: '#1976d2',
      }}
    >
      Calculation of Investment
    </Typography>
    <Divider sx={{ mb: 2, backgroundColor: '#1976d2' }} /> */}
   {/* <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 20px 0px"}}>Calculation of Investment in {rightsIssueDetails?.company_name}</h3>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 250 }} aria-label="calculation table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: '#424242' }}>
              Description
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: '#424242' }}>
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calculationSectionObj && Object.entries(calculationSectionObj["Calculation of Investment"]).map(([key, value], index) => (
            <TableRow
              key={index}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                '&:nth-of-type(even)': { backgroundColor: '#ffffff' },
              }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 500 }}>
                {key}
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: "#00796b"}}>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
  {/* </CardContent>
</Card> */}
   {/* <ul style={{listStyle:"none",paddingLeft:"0px"}}>
            {rightsIssueDetails?.p_tags_with_i  && JSON.parse(rightsIssueDetails?.p_tags_with_i).map((step, index) => (
              <li key={index} style={{padding:"3px 0px",fontSize:"13px",color:"#666"}}><i>{step}</i></li>
            ))}
   </ul> */}

      {/* Expected Profit from Buyback Section */}
      {/* <Card>
        <CardContent> */}
          {/* <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 20px 0px"}}>Expected Profit from the {rightsIssueDetails?.company_name} on the bases of acceptance Ratio:</h3>
         
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{backgroundColor:"#f5f5f5"}}>
                  <TableCell style={{fontWeight:"bold"}}>Acceptance Ratio</TableCell>
                  {["33%", "50%", "75%", "100%"].map((ratio) => (
                    <TableCell key={ratio} align="center" style={{fontWeight:"bold"}}>{ratio}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {calculationSectionObj && calculationSectionObj["Expected Profit from Buyback"].map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{color:"black"}}>{row["Acceptance Ratio"]}</TableCell>
                    {["33%", "50%", "75%", "100%"].map((ratio) => (
                      <TableCell key={ratio} align="center">{row[ratio]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          */}
       
   

    {/* {rightsIssueDetails?.objective && rightsIssueDetails.objective.trim() !== "<p></p>" && (<><h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"25px 0px 0px 0px",}}>{rightsIssueDetails?.company_name} - Necessity of the Objective</h3>
    <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}><div dangerouslySetInnerHTML={{ __html: rightsIssueDetails?.objective }} /></div></>)} */}

    <Grid container spacing={isMobile ? 0 : 2}>

{/* Company Contact Section */}
{companyContactObj && (
  <Grid item xs={12} md={6}>
    <Card 
      className="company-contact-section"
      sx={{
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        backgroundColor: '#fafafa',
        // padding: '5px 5px 5px 5px',
        border: '1px solid #ddd',
      }}
    >
      <CardContent style={{padding: '20px'}}>
        <Typography  gutterBottom  sx={{ fontWeight: 'bold',color:"#00796b",borderBottom: "1px solid #dddcdd" }}>
          Company Contact Information
        </Typography>
        <Typography sx={{ margin: "10px 0px 10px 0px", fontSize: '1rem' }}>
          <b>{companyContactObj.company_name}</b>
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px" }}>
          {companyContactObj.address}
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word'  }}>
          <b>Email:</b> {companyContactObj.email}
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px" }}>
          <b>Phone:</b> {companyContactObj.phone}
        </Typography>
        <Typography style={{fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word' }}>
          <b>Website:</b> 
          <a href={companyContactObj.website} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2', textDecoration: 'none',marginLeft:"5px" }}>
            {companyContactObj.website}
          </a>
        </Typography>
      </CardContent>
    </Card>
  </Grid>
)}

{/* Registrar Contact Section */}
{registrarContactObj && Object.keys(registrarContactObj).length !== 0 && registrarContactObj.registrar_name !== null && (
  <Grid item xs={12} md={6}>
    <Card 
      className="registrar-contact-section"
      sx={{
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        borderRadius: '10px',
        backgroundColor: '#fafafa',
        // padding: '20px',
        border: '1px solid #ddd',
      }}
    >
      <CardContent style={{padding: '20px'}}>
        <Typography  gutterBottom  sx={{ fontWeight: 'bold',color:"#00796b",borderBottom: "1px solid #dddcdd" }}>
          Registrar Contact Information
        </Typography>
        <Typography sx={{ margin: "10px 0px 10px 0px", fontSize: '1rem' }}>
          <b>{registrarContactObj.registrar_name}</b>
        </Typography>
        <Typography sx={{  marginBottom: '5px',fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word' }}>
          <b>Email:</b> {registrarContactObj.email}
        </Typography>
        <Typography sx={{ marginBottom: '5px',fontSize:"14px" }}>
          <b>Phone:</b> {registrarContactObj.phone}
        </Typography>
        <Typography sx={{ fontSize:"14px", wordWrap: 'break-word', 
    overflowWrap: 'break-word'  }}>
          <b>Website:</b> 
          <a href={registrarContactObj.website} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2', textDecoration: 'none',marginLeft:"5px" }}>
            {registrarContactObj.website}
          </a>
        </Typography>
      </CardContent>
    </Card>
  </Grid>
)}

    </Grid>



      {/* <Grid container spacing={isMobile ? 1 : 3}>
     
      {companyContactObj && ( <Grid item xs={12} md={6}>
        <Card className="company-contact-section">
          <CardContent>
            <Typography variant="h6">Company Contact</Typography>
            <Typography style={{ margin: "10px 0px 5px 0px" }}>
              <b>{companyContactObj["Company Name"]}</b>
            </Typography>
            <Typography><b>Address:</b> {companyContactObj.Address}</Typography>
            <Typography><b>Email:</b> {companyContactObj.Email}</Typography>
            <Typography><b>Phone:</b> {companyContactObj.Phone}</Typography>
            <Typography><b>Website:</b> <a href={companyContactObj.Website} target="_blank" rel="noopener noreferrer">{companyContactObj.Website}</a></Typography>
          </CardContent>
        </Card>
      </Grid>)}

    
{registrarContactObj && registrarContactObj && ( 
 
      <Grid item xs={12} md={6}>
        <Card className="registrar-contact-section">
          <CardContent>
            <Typography variant="h6">Registrar Contact</Typography>
            <Typography style={{ margin: "10px 0px 5px 0px" }}>
              <b>{registrarContactObj["Registrar Name"]}</b>
            </Typography>
            <Typography><b>Email:</b> {registrarContactObj.Email}</Typography>
            <Typography><b>Phone:</b> {registrarContactObj.Phone}</Typography>
            <Typography><b>Website:</b> <a href={registrarContactObj.Website} target="_blank" rel="noopener noreferrer">{registrarContactObj.Website}</a></Typography>
          </CardContent>
        </Card>
      </Grid>)}
     
    </Grid> */}

      {/* Company Contact */}
      {/* <Card className="company-contact-section">
        <CardContent>
          <Typography variant="h6">Company Contact</Typography>
          <Typography><b>Company Name:</b> {companyContactObj["Company Name"]}</Typography>
          <Typography><b>Address:</b> {companyContactObj.Address}</Typography>
          <Typography><b>Email:</b> {companyContactObj.Email}</Typography>
          <Typography><b>Phone:</b> {companyContactObj.Phone}</Typography>
          <Typography><b>Website:</b> <a href={companyContactObj.Website} target="_blank" rel="noopener noreferrer">{companyContactObj.Website}</a></Typography>
        </CardContent>
      </Card> */}

       {/* Registrar Contact Section */}
       {/* <Card className="registrar-contact-section">
        <CardContent>
          <Typography variant="h6">Registrar Contact</Typography>
          <Typography><b>Name:</b> {registrarContactObj["Registrar Name"]}</Typography>
          <Typography><b>Email:</b> {registrarContactObj.Email}</Typography>
          <Typography><b>Phone:</b> {registrarContactObj.Phone}</Typography>
          <Typography><b>Website:</b> <a href={registrarContactObj.Website} target="_blank" rel="noopener noreferrer">{registrarContactObj.Website}</a></Typography>
        </CardContent>
      </Card> */}
    {/* </div> </div>
  </div> */}


  </div> </div>
  </>
  );
};

export default RightsIssueDetails;
