import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
// // import { CardHeader } from "@mui/material";
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import ConfirmDialog from './ConfirmDialog';
import IPOStatusComponent from "./IPOStatusComponent";
import FaqComponent from './FaqComponent'; 

function CurrentIPOs({currentIPOs,deviceInfo,navigation}) {
  console.log(navigation);

  const faqData = [
    {
      question: "What is the current IPO?",
      answer: "The current IPO refers to the ongoing Initial Public Offering where a company is offering its shares to the public for the first time. The details about the company, issue price, and subscription dates are provided in the IPO prospectus."
    },
    {
      question: "How can I subscribe to the current IPO?",
      answer: "You can subscribe to the current IPO through your broker or online trading platforms. Ensure that you have a Demat account and sufficient funds to complete your application."
    },
    {
      question: "What are the key dates for the current IPO?",
      answer: "The key dates for the current IPO include the opening and closing dates for subscription, the allotment date, and the listing date. These dates are crucial for applying and tracking the IPO's progress."
    },
    {
      question: "What is the issue price for the current IPO?",
      answer: "The issue price for the current IPO is the price at which the shares are being offered to the public. This price can be fixed or within a price band, depending on whether it's a fixed price or book-building issue."
    },
    {
      question: "What is the minimum and maximum lot size for the current IPO?",
      answer: "The minimum lot size is the smallest number of shares you can apply for in the IPO, while the maximum lot size is the highest number of shares you can apply for within the retail investor category."
    },
    {
      question: "Where can I find the prospectus for the current IPO?",
      answer: "The prospectus for the current IPO can be found on the company's website, the stock exchange's website, or through your broker. It contains detailed information about the company, financials, risks, and the IPO itself."
    },
    {
      question: "What are the risks associated with the current IPO?",
      answer: "Investing in the current IPO carries risks such as market volatility, the potential for the stock price to drop after listing, and company-specific risks. It's important to read the prospectus and assess the risks before investing."
    },
    {
      question: "How do I check the subscription status of the current IPO?",
      answer: "The subscription status of the current IPO can be checked on the stock exchange's website, your broker's platform, or financial news portals. It provides information on how much of the IPO has been subscribed by different investor categories."
    },
    {
      question: "When will the allotment for the current IPO be announced?",
      answer: "The allotment for the current IPO is typically announced a few days after the IPO closes. You can check the allotment status on the registrar's website by entering your PAN number or application number."
    },
    {
      question: "What should I do if I do not receive any shares in the current IPO?",
      answer: "If you do not receive any shares in the current IPO, the funds you applied with will be refunded to your account. You can track the refund process through your bank or broker."
    },
    {
      question: "What is the expected listing date for the current IPO?",
      answer: "The expected listing date for the current IPO is the day the shares will start trading on the stock exchange. The exact date is usually announced after the allotment process is completed."
    },
    {
      question: "What is the grey market premium (GMP) for the current IPO?",
      answer: "The grey market premium (GMP) for the current IPO indicates the price at which the IPO shares are trading in the unofficial market before the listing. GMP can provide insight into investor sentiment but is not a guaranteed indicator of listing performance."
    },
    {
      question: "Can I modify or cancel my application for the current IPO?",
      answer: "You can modify or cancel your application for the current IPO before the subscription period ends. Contact your broker or use the online platform you applied through to make changes."
    },
    {
      question: "How can I sell my shares after the current IPO is listed?",
      answer: "You can sell your shares after the current IPO is listed by placing a sell order through your broker or online trading platform. The shares will trade on the stock exchange at the market price."
    }
  ];
  
 
  // const [iconPills, setIconPills] = React.useState("1");
  // const [pills, setPills] = React.useState("1");
  const [ipoType, setIpoType] = useState("All IPO");

  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  const handleIpoTypeChange = (type) => {
    setIpoType(type);
    setActiveTab(type);
    setShowAllRows(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ff7847",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }


  // Filter rows based on IPO type
  const filteredRows =
    ipoType === "All IPO"
      ? currentIPOs
      : currentIPOs.filter((row) => {
          if (ipoType === "Mainboard IPO") {
            const lastValue = row[8] && row[8].split(" ").pop();
            return lastValue === "NSE";
          } else if (ipoType === "SME IPO") {
            const lastValue = row[8] && row[8].split(" ").pop();
            return lastValue === "SME";
          }
        });

  const [showAllRows, setShowAllRows] = useState(false);
  const [activeTab, setActiveTab] = useState("All IPO");
  const initialRowCount = 6;

  const visibleRows = showAllRows ? filteredRows.length : initialRowCount;

  return (
    <>
    <Helmet>
    <title>{`Current IPO - Check List of Current IPOs in ${new Date().getFullYear()} | IPOtank`}</title>
    <meta name="description" content={`Current IPO: Get a list of all Current IPOs in ${new Date().getFullYear()} along with details like Date, lot size, Price, Subscription, allotment status and much more at IPO tank.`} />
    <meta name="keywords" content="Live IPO, Current IPO, latest IPOs, IPO details, IPOtank, IPO tank" />


    <meta property="og:url" content="https://www.ipotank.com/current-ipo" /> <meta property="og:type" content="website" /> <meta property="og:title" content={`Current IPO - Check List of Current IPOs in ${new Date().getFullYear()} | IPOtank`} /> <meta property="og:description" content={`Current IPO: Get a list of all Current IPOs in ${new Date().getFullYear()} along with details like Date, lot size, Price, Subscription, allotment status and much more at IPO tank.`} />

</Helmet>

      <div style={{backgroundImage: "url('../best8.jfif')",backgroundSize: "cover", backgroundRepeat: "no-repeat", padding:"10px 0 10px 0"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
           <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>

    {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        <Link to={`/current-ipo`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>Current IPO</p>
        </Link>
      </div>)}
      <br/>
    
      <h1 style={{marginTop:"-20px"}}>Current IPO</h1>
      <p style={{marginTop:"-10px", textAlign: "justify",fontSize:"15px"}}>"Current IPO" refers to an Initial Public Offering that is currently open for subscription or recently closed but not yet listed on a stock exchange.</p>
      {/* <h4 style={{marginTop:"10px"}}>A "Current IPO" refers to an Initial Public Offering that is currently open for subscription or recently closed but not yet listed on a stock exchange. It represents a pivotal moment for a private company transitioning to a publicly traded entity, offering shares to investors for the first time to raise capital.</h4> */}
      <p style={{marginTop:"0px", textAlign: "justify",fontSize:"15px"}}>List of all current IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Stay updated on the latest IPOs and their specifics in one place.</p>
      </div>
      </div>
      </div>
      <br/>
      {/* <br/> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
     <div style={{padding:"15px 15px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
     <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px auto",
    flexWrap: "wrap", // Allow items to wrap to next line
    gap: "10px", // Gap between items
  }}
>
  <div
    style={{
      backgroundColor: activeTab === "All IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "All IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("All IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "All IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0, // Reset default margin
      }}
    >
      All IPO
    </p>
  </div>
  <div
    style={{
      backgroundColor: activeTab === "Mainboard IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "Mainboard IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("Mainboard IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "Mainboard IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0,
      }}
    >
      Mainboard IPO
    </p>
  </div>
  <div
    style={{
      backgroundColor: activeTab === "SME IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "SME IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("SME IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "SME IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0,
      }}
    >
      SME IPO
    </p>
  </div>
     </div>

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          padding: "10px auto",
          // gap: "10px",
          // borderBottom:"2px solid #bdb4b8",
          // background: "green",
        }}
      >
        <div style={{
      backgroundColor:(activeTab === "All IPO") ? '#666' : '', padding:"0px 20px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "All IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("All IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "All IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "All IPO") ? '#ff7847' : '#666',
              color: (activeTab === "All IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            All IPO
          </p></div>
          <div style={{
      backgroundColor:(activeTab === "Mainboard IPO") ? '#666' : '', padding:"0px 25px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "Mainboard IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("Mainboard IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "Mainboard IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "Mainboard IPO") ? '#ff7847' : '#666',
              color: (activeTab === "Mainboard IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            Mainboard IPO
          </p>
          </div>
          <div style={{
      backgroundColor:(activeTab === "SME IPO") ? '#666' : '', padding:"0px 25px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "SME IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("SME IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "SME IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "SME IPO") ? '#ff7847' : '#666',
              color: (activeTab === "SME IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            SME IPO
          </p>
          </div>   </div> */}
        {/* <p
          onClick={() => handleIpoTypeChange("All IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
        //   style={{ 
        //   color: ipoType === "All IPO" ? "red" : "inherit"
          
      
        // }}
       
        >
          All IPO
        </p>
        <p
          onClick={() => handleIpoTypeChange("Mainboard IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
          // style={{ color: ipoType === "Main IPO" ? "red" : "inherit" }}
        >
          Mainboard IPO
        </p>
        <p
          onClick={() => handleIpoTypeChange("SME IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >
          SME IPO
        </p> */}
   

      


      {/* <br/> */}
        {/* <h2 style={{ textAlign: 'left' ,fontSize: "20px",fontWeight: "bold"}}>Current IPO</h2> */}
        {filteredRows?.length > 0 ? (
            <div style={{maxWidth:"1200px",width:"100%",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text',marginTop:"15px" }}>
            {filteredRows.slice(0, visibleRows).map((row, index) => (
             <>
             <div key={index} style={{marginBottom:"15px", width:"100%",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
              {/* <Grid item xs={12} sm={6} md={12} key={index}> */}
                {/* <Card>
                  <CardContent> */}
                  <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
                   {/* <div style={{borderBottom:"1px solid #00A877" ,width:"100%",padding:"10px 0"}}>
   <Typography  component="div">
     <Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontSize:"15px", fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
       <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}>
       <div style={{ display: "flex", alignItems: "center" }}>
         <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px", width: "50px" }} />
         {row[1]}
       </div>
       <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
       </div>
     </Link>
   </Typography>
                   </div> */}
                    <div style={{borderBottom:"1px solid #00A877" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
   <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px" }} />
   {row[1]}
 </div>
 {/* <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #00A877",borderRadius:"5px",backgroundColor:"#00A877",textDecoration:"none"}}>SME</label> :""}</div> */}
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/current-ipo`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div>
</div>
</Link>
</Typography>
<div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}

<div style={{marginLeft:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])} 
      /></div></div>
             </div>
                   <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[6] !== '') ? (<strong>₹ {row[6]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}
     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20}/><div>Subs</div></div></label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( <strong>
      {row[9].split(' ')[0].replace(/[^\d,]/g, '')}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
      <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {/* {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>} */}
     {(row[14] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {/* {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}{JSON.parse(row[14])["Subscription (times)"]} times
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
                  </div>
                  {/* </CardContent>
                </Card> */}
              {/* </Grid> */}
              </div>
              </>
            ))}
             {filteredRows.length > 6 ? (
            <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => setShowAllRows(!showAllRows)}
               className="py-0 text-left pl-8">
              {/* // <button */}
              {/* //   onClick={() => setShowAllRows(!showAllRows)}
              //   className="py-0 text-left pl-8"
              // > */}
                {showAllRows ? (
                  <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                  border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
             onMouseEnter={(e) => {
                e.target.style.borderColor = '#ff7847';
                e.target.style.color = '#ff7847';
              }} 
              onMouseLeave={(e) => {
                e.target.style.borderColor = '#666';
                e.target.style.color = '#666';
              }}>
                    Show Less 
                    {/* <TiArrowSortedUp/> */}
                  
                  </div>
                ) : (
                  <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                  border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
             onMouseEnter={(e) => {
                e.target.style.borderColor = '#ff7847';
                e.target.style.color = '#ff7847';
              }} 
              onMouseLeave={(e) => {
                e.target.style.borderColor = '#666';
                e.target.style.color = '#666';
              }}>
                    View More
                    {/* <TiArrowSortedDown /> */}
                    {/* <GoChevronDown/> */}
                   
                  </div>
                )}
             </div>):null}
            </div>
        // <TableContainer
        //   style={{ width: "100%", maxWidth: "1200px" }}
        //     component={Paper}
        // >
        //   <Table sx={{ minWidth: 350 }} aria-label="customized table">
           
        //       {filteredRows.length > initialRowCount && (
        //          <caption style={{ textAlign: "right", fontWeight: "bold" }} onClick={() => setShowAllRows(!showAllRows)}
        //          className="py-0 text-left pl-8">
              
        //           {showAllRows ? (
        //             <div className="flex " style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
                   
        //             onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        //             onMouseLeave={(e) => e.target.style.color = ''}>
        //               Show Less 
        //               <TiArrowSortedUp/>
                    
        //             </div>
        //           ) : (
        //             <div className="flex " style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
                   
        //             onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        //             onMouseLeave={(e) => e.target.style.color = ''}>
        //               View More
        //               <TiArrowSortedDown />
                     
                     
        //             </div>
        //           )}
        //        </caption>
        //       )}
          
        //     <TableHead>
        //       <TableRow>
        //         <StyledTableCell>Issuer Company</StyledTableCell>
        //         <StyledTableCell align="right">Open Date</StyledTableCell>
        //         <StyledTableCell align="right">Close Date</StyledTableCell>
        //         <StyledTableCell align="right">Listing Date</StyledTableCell>

        //         <StyledTableCell align="right">
        //           Issue Price (Rs)
        //         </StyledTableCell>
        //         <StyledTableCell align="right">
        //           Issue Size (Rs Cr.)
        //         </StyledTableCell>
        //         <StyledTableCell align="right">Lot Size</StyledTableCell>
        //         <StyledTableCell align="right">Exchange</StyledTableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {filteredRows.slice(0, visibleRows).map((row, index) => (
        //         <StyledTableRow key={index}>
                  
        //           <StyledTableCell component="th" scope="row">
        //             <Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
                               
        //             <div style={{ display: "flex", alignItems: "center" }}>
        //                 <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
        //                 {row[1]}
        //             </div>
        //             </Link>
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[3])} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[4])} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[5])}
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[6]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[7]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[8]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[9]} 
        //           </StyledTableCell>
        //         </StyledTableRow>
        //       ))}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
         ) : (
          <p style={{ textAlign: 'center'}}>No Current {ipoType} available.</p>
      )}
      </div>
      <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
        <br/>
        </div>


        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{marginTop:"5px", width: "92%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent  type="Current IPO" /></div></div>
     
    </>
  );
}

export default CurrentIPOs;
