import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      marginTop:"100px",
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      padding: '20px',
      color: '#333',
      fontSize:"15px",
    },
    header1: {
      fontSize: '2em',
      fontWeight: 'bold',
      marginBottom: '10px',
      paddingBottom: '10px',
      borderBottom:"1px solid #ccc"
    },
    header: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    sectionHeader: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    section: {
      marginBottom: '20px',
    },
    list: {
      paddingLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    contact: {
      marginTop: '30px',
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    emailLink: {
      color: '#007BFF',
      textDecoration: 'none',
    },
  };

  return (
    <><Helmet>
   <title>Privacy Policy IPOtank</title>
   <meta name="description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit IPO tank." />
   <meta name="keywords" content="Privacy Policy, personal information protection, data privacy, GDPR, CCPA, privacy practices, IPOtank, IPO tank" />
 
 
   <meta property="og:url" content="https://www.ipotank.com/PrivacyPolicy" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Privacy Policy IPOtank" />
    <meta property="og:description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit IPO tank." />
  </Helmet>

  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"30px",
      textAlign:"justify",
    }}
  >
       <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    {/* <div style={styles.container}> */}
      <div style={styles.header1} id="h-privacy-policy-for-IPOtank">
        Privacy Policy
      </div>
      <p>This Privacy Policy describes our policies and procedures regarding the collection, use, and disclosure of your information when you use our Service. It also informs you of your privacy rights and how applicable laws protect you.</p>
      <p>This Privacy Policy applies exclusively to our online activities and is valid for visitors to our website, ipotank.com, in relation to the information they share or that is collected through this site. It does not apply to any information gathered offline or through channels other than this website.</p>
      {/* <p>
        At IPOtank, one of our main priorities is the privacy of our visitors. This Privacy
        Policy document contains types of information that is collected and
        recorded by IPOtank and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to{' '}
        <a href="mailto:ipotank@gmail.com" style={styles.emailLink}>
          contact us
        </a>
        . Our Privacy Policy was created with the help of the{' '}
        <a
          href="https://www.generateprivacypolicy.com"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >
          Privacy Policy Generator
        </a>
        .
      </p> */}

      <h2 style={styles.header} id="h-log-files">
        {/* Log Files */}Information Collection and Use
      </h2>
      <p>To enhance your experience while using our service, we may ask you to provide certain personally identifiable information. This information will be securely retained and used in accordance with this privacy policy. for more details, you can refer to the privacy policies of the third-party service providers we work with:
        {/* IPOtank follows a standard procedure of using log files. These files
        log visitors when they visit websites. All hosting companies do this and
        a part of hosting services’ analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users’ movement on
        the website, and gathering demographic information. */}
      </p>
      <ul style={styles.list}>
      <li style={styles.listItem}><a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >Google Play Services</a></li>
      <li style={styles.listItem}><a
          href="https://support.google.com/admob/answer/6128543?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >AdMob</a></li>
      <li style={styles.listItem}><a
          href="https://firebase.google.com/policies/analytics"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >Google Analytics for Firebase</a></li>
      <li style={styles.listItem}><a
          href="https://firebase.google.com/support/privacy/"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >Firebase Crashlytics</a></li>
      <li style={styles.listItem}><a
          href="https://www.facebook.com/about/privacy/update/printable"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >Facebook</a></li>
</ul>

      <h2 style={styles.header} id="h-cookies-and-web-beacons">
        {/* Cookies and Web Beacons */}Usage Data
      </h2>
      {/* <p>
        Like any other website, IPOtank uses ‘cookies’. These cookies are used
        to store information including visitors’ preferences, and the pages on
        the website that the visitor accessed or visited. The information is
        used to optimize the users’ experience by customizing our web page
        content based on visitors’ browser type and/or other information.
      </p> */}
      <p>
      This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
        {/* For more general information on cookies, please read{' '}
        <a
          href="https://www.privacypolicyonline.com/what-are-cookies/"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >
          “What Are Cookies” from Cookie Consent
        </a>
        . */}
      </p>

      {/* <h2 style={styles.header} id="h-google-doubleclick-dart-cookie">
        Google DoubleClick DART Cookie
      </h2>
      <p>
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit to www.website.com and other sites on the internet. However,
        visitors may choose to decline the use of DART cookies by visiting the
        Google ad and content network Privacy Policy at the following URL –{' '}
        <a
          href="https://policies.google.com/technologies/ads"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >
          https://policies.google.com/technologies/ads
        </a>
        .
      </p> */}

      <h2 style={styles.header} id="h-our-advertising-partners">
        Our Advertising Partners
      </h2>
      <p>
      Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Google{' '}
          <a
            href="https://policies.google.com/technologies/ads"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.emailLink}
          >
            https://policies.google.com/technologies/ads
          </a>
        </li>
      </ul>

      <h2 style={styles.header} id="h-privacy-policies">
        {/* Privacy Policies */}
        Cookies
      </h2>
      <p>
      If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
        {/* You may consult this list to find the Privacy Policy for each of the
        advertising partners of IPOtank. */}
      </p>
      <p>
      If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
        {/* Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on IPOtank, which are sent
        directly to users’ browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit. */}
      </p>
      <p>
      When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.

        {/* Note that IPOtank has no access to or control over these cookies that
        are used by third-party advertisers. */}
      </p>
      <p>
      If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.

        {/* Note that IPOtank has no access to or control over these cookies that
        are used by third-party advertisers. */}
      </p>


      <h2 style={styles.header} id="h-privacy-policies">
        Security
      </h2>
      <p>
      We highly value the trust you place in us by providing your Personal Information, and we are committed to using commercially accepted practices to protect it. However, please keep in mind that no method of transmission over the internet or electronic storage is completely secure or foolproof. While we strive to safeguard your data, we cannot guarantee its absolute security.
      </p>


      <h2 style={styles.header} id="h-third-party-privacy-policies">
        {/* Third Party Privacy Policies */}Third-Party Links
      </h2>
      <p>
      Our Service may include links to external websites. If you choose to click on a third-party link, you will be directed to that site. Please note that these external websites are not operated by us, and we strongly recommend reviewing their Privacy Policies. We do not have control over, and we assume responsibility for, the content, privacy policies of any third-party sites or services.
        {/* IPOtank’s Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options. */}
      </p>
      {/* <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers’ respective
        websites. What Are Cookies?
      </p> */}

      <h2 style={styles.header} id="h-children-s-information">
        {/* Children’s Information */}Children's Privacy
      </h2>
      <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
        {/* Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity. */}
      </p>
      {/* <p>
        IPOtank does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p> */}

      <h2 style={styles.header} id="h-online-privacy-policy-only">
        {/* Online Privacy Policy Only */}
        Changes to this Privacy Policy
      </h2>
      <p>
      We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
        {/* This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in IPOtank. This policy is not applicable to any
        information collected offline or via channels other than this website. */}
      </p>
      <p>This policy is effective as of 2024-09-25.</p>

      {/* <h2 style={styles.header} id="h-consent">
        Consent
      </h2>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its Terms and Conditions.
      </p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been created
        with the help of the{' '}
        <a
          href="https://www.privacypolicies.com/privacy-policy-generator/"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.emailLink}
        >
          Privacy Policy Generator
        </a>
        .
      </p>

      <h2 style={styles.header} id="h-changes-to-this-privacy-policy">
        Changes to This Privacy Policy
      </h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify You of
        any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p> */}

      <h2 style={styles.header} id="h-contact-us">
        Contact Us
      </h2>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          By email: <a href="mailto:ipotank@gmail.com" style={styles.emailLink}>ipotank@gmail.com</a>
        </li>
      </ul>
    </div></div>
  
   
    
    </>
  );
};

export default PrivacyPolicy;
